import {BaseGame} from "./BaseGame";
import {DbImage} from "./DbImage";
import {UiText} from "./UiText";

export interface Sakura extends BaseGame{
    objects: DbImage[];
    background: DbImage;
    uiText: UiText | null;
    refillType: SakuraRefillType;
}

export type SakuraRefillType =
    |"Clear"
    |"Continuous";

export function isSakura(possible: any): possible is Sakura{
    const game = possible as Sakura;
    if(game.gameType === "Sakura")
    {
        return true;
    }
    return false;
}