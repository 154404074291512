import React, {Component, FormEvent, ReactNode} from 'react';
import authService from "../../api-authorization/AuthorizeService";
import {Button, Col, FormGroup, Jumbotron, Row} from "reactstrap";
import {Redirect, RouteComponentProps} from "react-router";
import {TextFormGroup} from "../../shared/TextFormGroup";
import {CreateRoboDanceRequest} from "../../../domain/CreateRoboDanceRequest";
import {RoboDance} from "../../../domain/RoboDance";
import {DbSound} from "../../../domain/DbSound";
import RoboDanceBackground from '../../../images/robodance_background.png';
import SoundFormGroup from "../../shared/SoundFormGroup";
import {DbOrUploadSound} from "../../../helper/ImageHelper";
import astroPreviewImage from "../../../images/charpreview/astro_preview.png";
import elephantPreviewImage from "../../../images/charpreview/elephant_preview.png";
import monsterPreviewImage from "../../../images/charpreview/monster_preview.png";
import robotPreviewImage from "../../../images/charpreview/robot_preview.png";
import girlPreviewImage from "../../../images/charpreview/girl_preview.png";
import doctorPreviewImage from "../../../images/charpreview/doctor_preview.png";
import {handleFetchError} from "../../shared/FormErrors";
import SubmitButton from "../../shared/SubmitButton";

interface MatchParams{
    id: string;
}
interface Props extends RouteComponentProps<MatchParams>{
}
interface State{
    form: CreateRoboDanceRequest;
    gameId: string | null;
    redirectToIndex: boolean;
    loading: boolean;
    error?: ReactNode;
    musicFile: DbSound | null;
}

export class RoboDanceCreate extends Component<Props, State>{
    robots = [
        {name: "Robot", value: "Robot", image: robotPreviewImage},
        {name: "Astronaut", value: "Astronaut", image: astroPreviewImage},
        {name: "Elephant", value: "Elephant", image: elephantPreviewImage},
        {name: "Monster", value: "Monster", image: monsterPreviewImage},
        {name: "Girl", value: "Girl", image: girlPreviewImage},
        {name: "Doctor", value: "Doctor", image: doctorPreviewImage}
    ];

    constructor(props: Props){
        super(props);
        const id = this.props.match.params.id;
        this.state = {
            form: {
                name: '',
                text: null,
                textPositionX: 0,
                textPositionY: 0,
                soundFile: null,
                removeSound: false,
                type: "Robot"
            },
            gameId: id || null,
            redirectToIndex: false,
            loading: false,
            musicFile: null,
        }
    }

    componentDidMount(): void {
        if(this.state.gameId){
            this.getGame(this.state.gameId).then();
        }
    }

    createFormData(){
        let form = new FormData();
        form.append("Name", this.state.form.name);
        if(this.state.form.text){
            form.append(`Text.Content`, this.state.form.text.content || "");
            form.append(`Text.FontSize`, JSON.stringify(this.state.form.text.fontSize));
            form.append(`Text.FontFamily`, this.state.form.text.fontFamily);
            form.append(`Text.FontColorHex`, this.state.form.text.fontColorHex);
            form.append("TextPositionX", JSON.stringify(this.state.form.textPositionX));
            form.append("TextPositionY", JSON.stringify(this.state.form.textPositionY));
        }
        if(this.state.form.soundFile){
            form.append("SoundFile", this.state.form.soundFile);
        }
        form.append("RemoveSound", JSON.stringify(this.state.form.removeSound));
        form.append("Type", this.state.form.type);
        return form;
    }

    async putGame(){
        this.setState({loading: true});

        const token = await authService.getAccessToken();
        const response = await fetch(`api/robodance/${this.state.gameId}`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            body: this.createFormData()
        });
        this.handleGameResponse(response, true);
    }

    async postGame(){
        this.setState({loading: true});

        const token = await authService.getAccessToken();
        const response = await fetch('api/robodance', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`
            },
            body: this.createFormData()
        });
        this.handleGameResponse(response, true);
    }

    async getGame(id: string){
        this.setState({loading: true});
        const token = await authService.getAccessToken();
        const response = await fetch(`api/robodance/${id}`, {
            headers: { 'Authorization': `Bearer ${token}` }
        });
        this.handleGameResponse(response, false);
    }

    async handleGameResponse(response: Response, redirectOnSuccess: boolean){
        try{
            if(response.status === 200){
                const data: RoboDance = await response.json();
                this.setState({
                    loading: false,
                    gameId: data.id,
                    form:{
                        ...this.state.form,
                        name: data.name,
                        text: data.uiText && data.uiText.text,
                        textPositionX: data.uiText && data.uiText.position ? data.uiText.position.x : 0,
                        textPositionY: data.uiText && data.uiText.position ? data.uiText.position.y : 0,
                        type: data.type,
                        removeSound: false,
                        soundFile: null
                    },
                    musicFile: data.musicFile,
                    redirectToIndex: redirectOnSuccess,
                });
            }
            else{
                this.setState({loading: false, error: await handleFetchError(response)});
            }
        }
        catch (e) {
            this.setState({loading: false, error: `Unknown error`});
        }
    }

    render(){

        if(this.state.redirectToIndex){
            return <Redirect to={'/robodance'} />;
        }

        const onSubmit = (event: FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            if(this.state.gameId){
                this.putGame().then();
            }
            else{
                this.postGame().then();
            }
        };

        return (
            <div>
                <Jumbotron>
                    <h1>RoboDance</h1>
                    <p>
                        RoboDance will let you dance with a character on the wall, <br/>
                        The character will try to do the same dance moves as you do, while you dance.<br/>
                        You can choose between {this.robots.length} characters.<br/>
                    </p>
                </Jumbotron>

                <section>
                    <h2>RoboDance</h2>
                    {this.state.error}
                    <form onSubmit={onSubmit}>
                        <FormGroup>
                            <label htmlFor='name'>Name</label>
                            <input
                                className='form-control'
                                type='text'
                                id='name'
                                name='name'
                                value={this.state.form.name}
                                onChange={(e) =>
                                    this.setState({
                                        form: {
                                            ...this.state.form,
                                            name: e.target.value
                                        }
                                    })
                                }
                            />
                        </FormGroup>

                        {this.state.musicFile &&
                            <FormGroup>
                                <label>Current sound</label>
                                <DbOrUploadSound audio={this.state.musicFile} />
                                <Button
                                    onClick={() =>
                                        this.setState({
                                            form:{
                                                ...this.state.form,
                                                removeSound: true,
                                            },
                                            musicFile: null
                                        })}
                                >
                                    Remove current sound
                                </Button>
                            </FormGroup>
                        }

                        <SoundFormGroup
                            label='Music file'
                            onSubmit={(sut) => this.setState({
                            form:{
                                ...this.state.form,
                                soundFile: sut.soundFile,
                                removeSound: false,
                            },
                            musicFile: null
                        })}
                        />

                        <FormGroup>
                            <label htmlFor='robotype'>Character</label>
                            <select
                                id='robotype'
                                className='form-control'
                                value={this.state.form.type}
                                onChange={(e) =>
                                    //@ts-ignore
                                    this.setState({
                                        form: {
                                            ...this.state.form,
                                            type: e.target.value
                                        }
                                    })
                                 }
                            >
                                {this.robots.map(x => <option key={x.value} value={x.value}>{x.name}</option>)}
                            </select>
                            <Row>
                                {this.robots.map(x =>
                                    <Col sm={2} key={x.value}>
                                        <img
                                            src={x.image}
                                            alt={x.name}
                                            style={{width: "100%"}}
                                            className={x.value === this.state.form.type ? 'active' : ''}
                                            onClick={() =>
                                                //@ts-ignore
                                                this.setState({
                                                    form: {
                                                        ...this.state.form,
                                                        type: x.value
                                                    }
                                                })
                                            }
                                        />
                                    </Col>
                                )}
                            </Row>

                        </FormGroup>

                        <TextFormGroup
                            text={this.state.form.text}
                            onTextChange={(t) => this.setState({form: {...this.state.form, text: t}})}
                            onPosChange={(p) => this.setState({form: {...this.state.form, textPositionX: p.x, textPositionY: p.y}})}
                            textPos={{x:this.state.form.textPositionX, y: this.state.form.textPositionY}}
                            background={{imagePath: RoboDanceBackground, id:"fake", thumbPath: RoboDanceBackground}}
                        />

                        {this.state.error}
                        <SubmitButton disabled={this.state.loading} isCreate={!!this.state.gameId}  />
                    </form>
                </section>
            </div>
        )
    }
}