import * as React from 'react';
import {Component, CSSProperties} from 'react';
import {DbImage} from "../../domain/DbImage";
import {UiText} from "../../domain/UiText";

interface State{
    textPosPct: {
        x: number;
        y: number;
        pct: number;
    },
    wrapperSet: boolean;
}
interface Props{
    uiText: UiText | null;
    background: DbImage | null;
    style?: CSSProperties;
}

export class ImageWithTextPreview extends Component<Props,State>{

    wrapperRef = React.createRef<HTMLDivElement>();

    constructor(props: Props){
        super(props);

        this.state = {
            textPosPct: {
                x: 0,
                y: 0,
                pct: 1
            },
            wrapperSet: false
        }
    }

    updatePctFromProps(){
        const target = this.wrapperRef.current;
        if(!target) return false;
        const rect = target.getBoundingClientRect();
        const pct =  rect.width / 1280;
        if(this.props.uiText && this.props.uiText.position){
            const xPos = this.props.uiText.position.x;
            const yPos = this.props.uiText.position.y;
            const pctX = xPos / 1280;
            const pctY = yPos / 800;
            this.setState({wrapperSet: true, textPosPct: {x: pctX*100, y: pctY*100, pct}});
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if(
            (this.props.uiText && this.props.uiText.position  && this.props.uiText.position.x)
            !==
            (prevProps.uiText && prevProps.uiText.position && prevProps.uiText.position.x)
        ){
            this.updatePctFromProps();
        }
        if(
            (this.props.uiText && this.props.uiText.text && this.props.uiText.text.fontSize)
            !==
            (prevProps.uiText && prevProps.uiText.text && prevProps.uiText.text.fontSize)
        ){
            this.updatePctFromProps();
        }
        if(
            (this.props.uiText && this.props.uiText.text && this.props.uiText.text.content)
            !==
            (prevProps.uiText && prevProps.uiText.text &&  prevProps.uiText.text.content)
        ){
            this.updatePctFromProps();
        }
    }

    componentDidMount(): void {
        this.updatePctFromProps();
    }

    render(){

        return(
            <div className='game-preview-container' style={this.props.style}>
                <div
                    className='game-preview'
                    ref={this.wrapperRef}
                    style={{backgroundImage: `url("${this.props.background && this.props.background.imagePath}")`}}
                >
                    {this.props.uiText && this.props.uiText.text &&
                    <div
                        className='text-preview'
                        dir='auto'
                        style={{
                            left: `${this.state.textPosPct.x}%`,
                            top: `${this.state.textPosPct.y}%`,
                            fontFamily: this.props.uiText.text.fontFamily,
                            color: this.props.uiText.text.fontColorHex,
                            fontSize: this.props.uiText.text.fontSize * this.state.textPosPct.pct,
                            textAlign: this.props.uiText.text.textAlignment,
                            transform: `rotate(${this.props.uiText.text.fontRotation}deg)`
                        }}>
                        {this.props.uiText.text.content}
                    </div>
                    }
                </div>
            </div>
        )
    }
}