import * as React from 'react';
import {Component} from 'react';
import {Redirect, RouteComponentProps} from "react-router";
import {FormEvent} from "react";
import authService from "./AuthorizeService";
import {Button} from "reactstrap";

interface Props extends RouteComponentProps{}
interface State{
    email: string;
    password: string;
    redirect: boolean;
}

export default class Login extends Component<Props, State>{
    private _subscription!: number;

    constructor(props: Props){
        super(props);

        this.state = {
            email: '',
            password: '',
            redirect: false
        }
    }

    componentDidMount(): void {
        this._subscription = authService.subscribe(() => this.authenticationChanged());
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    authenticationChanged() {
        if(authService.isAuthenticated()){
            this.setState({redirect: true});
        }
    }

    render(){
        if(this.state.redirect) {
            const params = new URLSearchParams(this.props.location.search);
            const returnUrl = params.get('returnUrl') || '/';
            return <Redirect to={returnUrl} />;
        }

        const onSubmit = (e: FormEvent<HTMLFormElement>) => {
            e.stopPropagation();
            e.preventDefault();

            if(this.state.email && this.state.password){
                authService.signIn(this.state.email, this.state.password).then();
            }
        };

        return(
            <div>
                <h1>Login</h1>
                <form onSubmit={onSubmit}>
                    <div className='form-group'>
                        <label htmlFor='email'>Email</label>
                        <input
                            className='form-control'
                            type='text'
                            value={this.state.email}
                            name='email'
                            id='email'
                            onChange={e => this.setState({email: e.target.value})}
                        />
                        <label htmlFor='password'>Password</label>
                        <input
                            className='form-control'
                            type='password'
                            value={this.state.password}
                            name='password'
                            id='password'
                            onChange={e => this.setState({password: e.target.value})}
                        />
                        <div className='form-group'>
                            <Button type='submit'>Login</Button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}