import * as React from 'react';
import {Component, FormEvent} from 'react';
import {Button, FormGroup, Modal, ModalBody, ModalHeader} from "reactstrap";
import {CompactPicker} from 'react-color';
import {DbText, TextAlignment} from "../../domain/DbText";
import {MouseEvent} from "react";
import {DbImage} from "../../domain/DbImage";
import {Slider} from "@material-ui/core";

interface State{
    content: string;
    fontFamily: string;
    fontSize: number;
    fontColorHex: string;
    fontRotation: number;
    wrapperSet: boolean;
    textAlignment: TextAlignment;
    textMove: boolean;
    textPosPct: {
        x: number;
        y: number;
        pct: number;
    }
}

interface Props {
    onSubmit: (text: DbText) => void;
    onCancel: () => void;
    text?: DbText;
    background?: DbImage | null;
    onPosChange: (p: {x: number, y: number}) => void;
    textPos: {
        x: number;
        y: number;
    };
}

export class TextForm extends Component<Props,State>{

    wrapperRef: HTMLDivElement|null = null;
    setWrapperRef: any;

    constructor(props: Props){
        super(props);
        this.state = {
            textMove: false,
            textPosPct: {
                pct: 1,
                x: 0,
                y: 0
            },
            wrapperSet: false,
            content: props.text ? props.text.content : "",
            fontFamily : props.text ? props.text.fontFamily : "Lato",
            fontColorHex : props.text ? props.text.fontColorHex : "#000000",
            fontSize : props.text ? props.text.fontSize : 16,
            // @ts-ignore
            textAlignment: props.text ? props.text.textAlignment.toLowerCase() : "left",
            fontRotation: props.text ? props.text.fontRotation : 0
        };

        this.setWrapperRef = (element: HTMLDivElement) => {
            this.wrapperRef = element;
            this.updatePctFromProps();
        };
    }

    updatePctFromProps(){
        const target = this.wrapperRef;
        if(!target) return false;
        const rect = target.getBoundingClientRect();
        const pct =  rect.width / 1280;
        const xPos = this.props.textPos.x;
        const yPos = this.props.textPos.y;
        const pctX = xPos / 1280;
        const pctY = yPos / 800;
        this.setState({wrapperSet: true, textPosPct: {x: pctX*100, y: pctY*100, pct}});
    }

    shouldComponentUpdate(nextProps: Readonly<Props>, nextState: Readonly<State>, nextContext: any): boolean {

        return true;
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if(!this.state.wrapperSet && this.wrapperRef){
            this.updatePctFromProps();
            return;
        }
        if(this.props.textPos.x !== prevProps.textPos.x){
            this.updatePctFromProps();
            return;
        }
        if((this.props.text && this.props.text.fontSize) !== (prevProps.text && prevProps.text.fontSize)){
            this.updatePctFromProps();
            return;
        }
        if((this.props.text && this.props.text.content) !== (prevProps.text && prevProps.text.content)){
            this.updatePctFromProps();
            return;
        }
    }


    componentDidMount(): void {
        this.updatePctFromProps();
    }

    render(){

        const submit = (e: FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            e.stopPropagation();
            this.props.onSubmit(this.state);
            this.props.onCancel();
        };

        const gamePreviewClick = (e: MouseEvent<HTMLDivElement>, force?: boolean) => {
            if(!this.state.textMove && !force) return;

            const target = this.wrapperRef;
            if(!target) return;

            const rect = target.getBoundingClientRect();
            const pct =  rect.width / 1280;
            const x = e.clientX - rect.left; //x position within the element.
            const y = e.clientY - rect.top;
            const pctX = x / rect.width;
            const pctY = y / rect.height;
            const xPos = Math.round(1280*pctX);
            const yPos = Math.round(800*pctY);
            this.setState({textPosPct: {x: pctX*100, y: pctY*100, pct}});
            this.props.onPosChange({x: xPos, y: yPos});
        };

        return(
            <Modal isOpen={true} backdrop={true} toggle={this.props.onCancel}>
                <ModalHeader toggle={this.props.onCancel}>
                    Create Text
                </ModalHeader>
                <ModalBody>
                    <form onSubmit={submit}>
                        <FormGroup>
                            <label htmlFor='content'>Content</label>
                            <textarea
                                className='form-control line-height-normal'
                                id='content'
                                name='content'
                                value={this.state.content}
                                onChange={e => this.setState({content: e.target.value})}
                                rows={4}
                                dir='auto'
                            />
                        </FormGroup>
                        <FormGroup>
                            <label htmlFor='textAlignment'>Alignment</label>
                            <select
                                id='textAlignment'
                                name='textAlignment'
                                className='form-control'
                                onChange={e => {
                                    //@ts-ignore
                                    this.setState({
                                        textAlignment: e.target.value
                                    })
                                }}
                                value={this.state.textAlignment}
                            >
                                <option value='left'>Left</option>
                                <option value='center'>Center</option>
                                <option value='right'>Right</option>
                            </select>
                        </FormGroup>
                        <FormGroup>
                            <label htmlFor='fontFamily'>Font Family</label>
                            <select className='form-control' onChange={e => this.setState({fontFamily: e.target.value})} value={this.state.fontFamily} >
                                <option>Lato</option>
                                <option>Boogaloo</option>
                                <option>Cabin Sketch</option>
                                <option>Eater</option>
                                <option>Gloria Hallelujah</option>
                                <option>Inconsolata</option>
                                <option>Luckiest Guy</option>
                                <option>Ropa Sans</option>
                                <option>Shadows Into Light</option>
                                <option>Spirax</option>
                                <option>Chewy</option>
                                <option>Abraham</option>
                                <option>Comix No2 CLM</option>
                                <option>Dana Yad AlefAlefAlef</option>
                                <option>Ellinia CLM</option>
                                <option>Gan CLM</option>
                                <option>Noot</option>
                                <option>Secular One</option>
                                <option>Simple CLM</option>
                                <option>Trashim CLM</option>
                                <option>Yehuda CLM</option>
                            </select>
                        </FormGroup>
                        <FormGroup>
                            <label htmlFor='fontSize'>Size</label>
                            <select id='fontSize' name='fontSize' className='form-control' onChange={e => this.setState({fontSize: +e.target.value})} value={this.state.fontSize.toString()} >
                                <option>12</option>
                                <option>14</option>
                                <option>16</option>
                                <option>18</option>
                                <option>20</option>
                                <option>24</option>
                                <option>28</option>
                                <option>32</option>
                                <option>36</option>
                                <option>42</option>
                                <option>50</option>
                                <option>64</option>
                                <option>80</option>
                                <option>100</option>
                                <option>124</option>
                                <option>150</option>
                                <option>176</option>
                                <option>200</option>
                            </select>
                        </FormGroup>
                        <FormGroup>
                            <label>Color</label>
                            <div>
                                <CompactPicker color={this.state.fontColorHex} onChangeComplete={(color) => this.setState({fontColorHex: color.hex})} />
                                <div className='clearfix'/>
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <label>Rotation</label>
                            <div>
                                <Slider
                                    min={0}
                                    max={360}
                                    step={5}
                                    value={this.state.fontRotation}
                                    onChange={(evt, number) => this.setState({fontRotation: Array.isArray(number) ? number[0] : number})}
                                    onChangeCommitted={(evt, number) => this.setState({fontRotation: Array.isArray(number) ? number[0] : number})}
                                />
                                <div className='clearfix'/>
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <Button type='submit' >Create</Button>
                        </FormGroup>
                    </form>
                    <div>
                        <p>Drag your text around the box, to decide position inside the game.</p>
                        <div className='game-preview-container'>
                            <div
                                className='game-preview'
                                ref={this.setWrapperRef}
                                onClick={(e) => gamePreviewClick(e, true)}
                                onMouseMoveCapture={gamePreviewClick}
                                onMouseDown={() => this.setState({textMove: true})}
                                onMouseUp={() => this.setState({textMove: false})}
                                onMouseLeave={() => this.setState({textMove: false})}
                                style={{backgroundImage: `url("${this.props.background && this.props.background.imagePath}")`}}
                            >
                                <div
                                    className='text-preview'
                                    dir='auto'
                                    style={{
                                        left: `${this.state.textPosPct.x}%`,
                                        top: `${this.state.textPosPct.y}%`,
                                        fontFamily: this.state.fontFamily,
                                        color: this.state.fontColorHex,
                                        fontSize: this.state.fontSize * this.state.textPosPct.pct,
                                        textAlign: this.state.textAlignment,
                                        transform: `rotate(${this.state.fontRotation}deg)`
                                    }}>
                                    {this.state.content}
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        )
    }

}