import React, {ReactNode} from 'react';
import {Component, FormEvent} from 'react';
import authService from "../../api-authorization/AuthorizeService";
import {DbImage} from "../../../domain/DbImage";
import {Button, FormGroup, Jumbotron} from "reactstrap";
import {CreateScratchRequest} from "../../../domain/CreateScratchRequest";
import {Redirect, RouteComponentProps} from "react-router";
import ImageBank from "../../shared/ImageBank";
import {DbText} from "../../../domain/DbText";
import {Scratch} from "../../../domain/Scratch";
import {TextFormGroup} from "../../shared/TextFormGroup";
import {Checkbox} from "@material-ui/core";
import SubmitButton from "../../shared/SubmitButton";
import {handleFetchError} from "../../shared/FormErrors";

interface MatchParams{
    id: string;
}
interface Props extends RouteComponentProps<MatchParams>{
}

interface State{
    gameId?: string;
    loading: boolean;
    error?: ReactNode;
    name: string;
    foreground: string;
    background: string;
    backgroundImage: DbImage | null;
    foregroundImage: DbImage | null;
    gameCreateLoad: boolean;
    images: Array<DbImage>;
    loadingImages: boolean;
    uploadingImage: boolean;
    uploadError: string | false;
    showUpload: boolean;
    selectForeground: boolean;
    selectBackground: boolean;
    redirectToIndex: boolean;
    textOnForeground: boolean;
    text: DbText | null;
    textPos: {
        x: number;
        y: number;
    }
}

export class ScratchCreate extends Component<Props, State>{

    constructor(props: Props){
        super(props);
        const id = this.props.match.params.id;
        this.state = {
            gameId: id || undefined,
            loading: false,
            name: '',
            foreground: '',
            background: '',
            backgroundImage: null,
            foregroundImage: null,
            text: null,
            images: [],
            gameCreateLoad: !!(id),
            loadingImages: true,
            uploadingImage: false,
            uploadError: false,
            showUpload: false,
            selectForeground: false,
            selectBackground: false,
            redirectToIndex: false,
            textOnForeground: false,
            textPos: {
                x: 0,
                y: 0,
            }
        }
    }

    componentDidMount(): void {
        if(this.state.gameId){
            this.getScratchGame(this.state.gameId).then();
        }
    }

    async putEditGame(){
        this.setState({loading: true});
        const body:CreateScratchRequest = {
            backgroundId: this.state.background,
            foregroundId: this.state.foreground,
            name: this.state.name,
            text: this.state.text,
            textPositionX: this.state.textPos.x,
            textPositionY: this.state.textPos.y,
            textOnForeground: this.state.textOnForeground
        };
        const token = await authService.getAccessToken();
        const response = await fetch(`api/scratch/${this.state.gameId}`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        });
        this.handleGameResponse(response, true);
    }

    async postNewGame(){
        this.setState({loading: true});
        const body:CreateScratchRequest = {
            backgroundId: this.state.background,
            foregroundId: this.state.foreground,
            name: this.state.name,
            text: this.state.text,
            textPositionX: this.state.textPos.x,
            textPositionY: this.state.textPos.y,
            textOnForeground: this.state.textOnForeground
        };
        const token = await authService.getAccessToken();
        const response = await fetch('api/scratch', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        });
        this.handleGameResponse(response, true);
    }

    async getScratchGame(id: string){
        const token = await authService.getAccessToken();
        const response = await fetch(`api/scratch/${id}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        this.handleGameResponse(response, false);

    }

    async handleGameResponse(response: Response, redirectOnSuccess: boolean){
        try{
            if(response.status === 200){
                const data: Scratch = await response.json();
                this.setState({
                    name: data.name,
                    foreground: data.foreground ? data.foreground.id : '',
                    background: data.background ? data.background.id : '',
                    backgroundImage: data.background,
                    foregroundImage: data.foreground,
                    gameCreateLoad: false,
                    text: data.uiText && data.uiText.text,
                    textOnForeground: data.textOnForeground,
                    textPos: {
                        x: data.uiText && data.uiText.position ? data.uiText.position.x : 0,
                        y: data.uiText && data.uiText.position ? data.uiText.position.y : 0
                    },
                    redirectToIndex: redirectOnSuccess,
                });
            }
            else{
                this.setState({loading: false, error: await handleFetchError(response)});
            }
        }
        catch (e) {
            this.setState({loading: false, error: `Unknown error`});
        }
    }


    render() {
        if(this.state.redirectToIndex) return <Redirect to={'/scratch'} />;

        const selectImage = (img: DbImage) => {
            if(this.state.selectForeground){
                this.setState({foreground: img.id, selectForeground: false, foregroundImage: img});
            }
            if(this.state.selectBackground){
                this.setState({background: img.id, selectBackground: false, backgroundImage: img});
            }
        };

        const onSubmit = (e: FormEvent<HTMLFormElement>) => {
            e.stopPropagation();
            e.preventDefault();

            if(this.state.gameId){
                this.putEditGame().then();
            }
            else{
                this.postNewGame().then();
            }

        };

        return(
            <div>
                <Jumbotron>
                    <h1>Scratch</h1>
                    <p>
                        Scratch has a scene with two images a foreground and background.<br/>
                        You can use your hands and body to scratch off the foreground to make the background visible<br/>
                        You can upload your own foreground and background to use in scratch<br/>
                    </p>
                </Jumbotron>
                {
                    this.state.gameCreateLoad ? <div>Creating game...</div> :
                        <div>
                            <h2>Scratch form</h2>
                            {this.state.error}
                            <form onSubmit={onSubmit}>
                                <FormGroup>
                                    <label htmlFor='name'>Name</label>
                                    <input className='form-control' type='text' id='name' name='name' value={this.state.name} onChange={(e) => this.setState({name: e.target.value})} />
                                </FormGroup>
                                <FormGroup>
                                    <label htmlFor='foreground'>Foreground</label>
                                    <input className='form-control' type='hidden' id='foreground' name='foreground' readOnly={true} value={this.state.foreground} />
                                    <div>
                                        <Button color='info' onClick={() => this.setState({selectForeground: !this.state.selectForeground})}>{this.state.selectForeground ? 'Cancel' : 'Select foreground'}</Button>
                                        {this.state.selectForeground && <p>Click an image in the image bank</p>}
                                    </div>
                                </FormGroup>
                                <FormGroup>
                                    <label htmlFor='background'>Background</label>
                                    <input className='form-control' type='hidden' id='background' name='background' readOnly={true} value={this.state.background} />
                                    <div>
                                        <Button color='warning' onClick={() => this.setState({selectBackground: !this.state.selectBackground})}>{this.state.selectBackground ? 'Cancel' : 'Select background'}</Button>
                                        {this.state.selectBackground && <p>Click an image in the image bank</p>}
                                    </div>
                                </FormGroup>

                                <ImageBank
                                    title={'Scratch image bank'}
                                    subtitle={
                                        this.state.selectForeground ?
                                            'Click an image to make it the foreground image' :
                                            this.state.selectBackground ?
                                                'Click an image to make it the background image' : ''
                                    }
                                    endpoint={'api/image/fullscreen'}
                                    onImageClick={(img) => selectImage(img)}
                                    setImageClass={(imgId) => this.state.foreground === imgId ? 'selected' : this.state.background === imgId ? 'selected-sec' : ''}
                                    aspectRatio={1280/800}
                                    fixedAspect={false}
                                />

                                <FormGroup>
                                    <label>Text On Foreground</label>
                                    <Checkbox
                                        checked={this.state.textOnForeground}
                                        onChange={() => {this.setState({textOnForeground: !this.state.textOnForeground})}}
                                    />
                                </FormGroup>

                                <TextFormGroup
                                    text={this.state.text}
                                    onTextChange={(t) => this.setState({text: t})}
                                    onPosChange={(p) => this.setState({textPos: p})}
                                    textPos={this.state.textPos}
                                    background={this.state.textOnForeground ? this.state.foregroundImage : this.state.backgroundImage}
                                />

                                {this.state.error}
                                <SubmitButton disabled={this.state.loading} isCreate={!!this.state.gameId}  />
                            </form>
                        </div>
                }
            </div>
        )
    }
}