import * as React from 'react';
import { Component } from 'react';
import {Link} from "react-router-dom";
import {Button, Table} from "reactstrap";
import {GameFlow} from "../../domain/GameFlow";
import {GameFlowData} from "../../data/GameFlowData";
import {isResponseError, ResponseError} from "../../domain/ResponseError";

interface Props{

}
interface State{
    gameFlows: Array<GameFlow>;
    loading: boolean;
    deleteSure: false|string;
    activeGameFlowId: string;
    error: ResponseError|false;
}

export default class GameFlowIndex extends Component<Props, State>{

    _gameFlowStore = new GameFlowData();

    constructor(props:Props){
        super(props);

        this.state = {
            gameFlows: [],
            loading: false,
            deleteSure: false,
            activeGameFlowId : '',
            error: false
        }
    }

    componentDidMount(): void {
        this.setState({loading: true});
        this._gameFlowStore.getGameFlows().then(data => {
            if(!isResponseError(data)){
                this.setState({ gameFlows: data, loading: false });
            }
            else{
                this.setState({error: data, loading: false});
            }

        });
        this._gameFlowStore.getActiveFlow().then(data => {
            if(!isResponseError(data)){
                this.setState({ activeGameFlowId: data.gameFlowId });
            }
            else{
                this.setState({error: data, loading: false});
            }

        });
    }

    deleteGameFlow(id: string){
        if(this.state.deleteSure !== id){
            this.setState({deleteSure: id});
            return;
        }

        this.setState({loading: true});
        this._gameFlowStore
            .deleteGameFlow(id)
            .then(status => {
                if(!isResponseError(status)){
                    this.setState({ gameFlows: this.state.gameFlows.filter(x => x.id !== id), loading: false});
                }
                else{
                    this.setState({loading: false, error: status});
                }
        });
    }


    render(){
        return(
            <div>
                <h1>Gameflow</h1>
                <div>
                    <Link to="/gameflow/create">
                        <Button className={'mb-2'} color='primary'>
                            Create new
                        </Button>
                    </Link>
                </div>
                { this.state.error && <div>{this.state.error.errorMsg}</div> }
                { this.state.loading ? <p>Loading...</p> :
                    <section>
                        <Table className='gameflow-table'>
                            <thead>
                            <tr>
                                <th>Name</th>
                                <th>Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.gameFlows.map(x => {
                                return(
                                    <tr key={x.id} className={this.state.activeGameFlowId ===  x.id ? 'active' : ''}>
                                        <td>{x.name}</td>
                                        <td>
                                            <div className='btn-group-custom'>
                                                <Link to={`/gameflow/edit/${x.id}`}> <Button color='info' size='sm'>edit</Button></Link>
                                                {this.state.activeGameFlowId !== x.id &&
                                                    <Button
                                                        color='danger'
                                                        size='sm'
                                                        onClick={() => {this.deleteGameFlow(x.id)}}
                                                    >
                                                        {this.state.deleteSure === x.id ? 'are you sure?' : 'delete' }
                                                    </Button>
                                                }
                                                {this.state.activeGameFlowId !== x.id &&
                                                    <Button
                                                        size='sm'
                                                        color='success'
                                                        onClick={() => {
                                                            this.setState({loading: true});
                                                            this._gameFlowStore.setActiveFlow(x.id)
                                                                .then(data => {
                                                                    if(isResponseError(data)){
                                                                        this.setState({
                                                                            error: data,
                                                                            loading: false
                                                                        });
                                                                    }
                                                                    else{
                                                                        this.setState({
                                                                            activeGameFlowId: data.gameFlowId,
                                                                            loading: false
                                                                        });
                                                                    }

                                                                })
                                                        }}
                                                    >
                                                        set live
                                                    </Button>
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </Table>
                    </section>
                }
            </div>
        )
    }
}