import authService from "../components/api-authorization/AuthorizeService";
import {handleResponse} from "../helper/ResponseHelper";


export class GameData{
    async getGames<T>(gameType: string){
        const token = await authService.getAccessToken();
        const url = `api/${gameType}`;
        const response = await fetch(url, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        return await handleResponse<T[]>(response, url);
    }

    async getGame<T>(gameType: string, gameId: string){
        const token = await authService.getAccessToken();
        const url = `api/${gameType}/${gameId}`;
        const response = await fetch(`api/${gameType}/${gameId}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        return await handleResponse<T>(response, url);
    }
}