import * as React from 'react';
import {Component} from 'react';
import {Button, FormGroup} from "reactstrap";
import {DbText} from "../../domain/DbText";
import {TextForm} from "./TextForm";
import {DbImage} from "../../domain/DbImage";
import {ImageWithTextPreview} from "./ImageWithTextPreview";

interface State{
    showCreateText: boolean;
}
interface Props{
    text: DbText | null;
    onTextChange: (t: DbText|null) => void;
    onPosChange: (p: {x: number, y: number}) => void;
    background?: DbImage | null;
    textPos: {
        x: number;
        y: number;
    };
}

export class TextFormGroup extends Component<Props,State>{

    constructor(props: Props){
        super(props);

        this.state = {
            showCreateText: false
        }
    }

    render(){

        return(
            <FormGroup>
                <label>Text</label>
                <div style={{maxWidth: "400px"}}>
                    <ImageWithTextPreview
                        uiText={{
                            text: this.props.text,
                            position: this.props.textPos
                        }}
                        background={this.props.background || null}
                    />
                </div>
                <div>
                    <div className='btn-group-custom'>
                        <Button color='secondary' onClick={() => this.setState({showCreateText: true})}>{this.props.text ? 'Update text' : 'Create text'}</Button>
                        {this.props.text && <Button color='danger' onClick={() => this.props.onTextChange(null)}>Remove text</Button>}
                    </div>
                </div>
                {this.state.showCreateText &&
                    <TextForm
                        onSubmit={this.props.onTextChange}
                        onCancel={() => this.setState({showCreateText: false})}
                        text={this.props.text || undefined}
                        textPos={this.props.textPos}
                        onPosChange={this.props.onPosChange}
                        background={this.props.background}
                    />
                }
            </FormGroup>
        )
    }
}