import authService from "../components/api-authorization/AuthorizeService";
import {handleResponse} from "../helper/ResponseHelper";
import {AccountCreateModel, AccountModel, AccountUpdateModel, ChangePassword} from "../domain/AccountModel";

export class AccountData{
    async getAll(){
        const token = await authService.getAccessToken();
        const url = `api/account`;
        const response = await fetch(url, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        return await handleResponse<AccountModel[]>(response, url);
    }

    async get(id: string){
        const token = await authService.getAccessToken();
        const url = `api/account/${id}`;
        const response = await fetch(url, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${token}`
            },

        });

        return await handleResponse<AccountModel>(response, url);
    }

    async put(id: string, body: AccountUpdateModel){
        const token = await authService.getAccessToken();
        const url = `api/account/${id}`;
        const response = await fetch(url, {
            method: "PUT",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        });

        return await handleResponse<AccountModel>(response, url);
    }

    async post(body: AccountCreateModel){
        const token = await authService.getAccessToken();
        const url = `api/account`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        });

        return await handleResponse<AccountModel>(response, url);
    }

    async delete(id: string){
        const token = await authService.getAccessToken();
        const url = `api/account/${id}`;
        const response = await fetch(url, {
            method: "DELETE",
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });

        return await handleResponse<{}>(response, url);
    }

    async changePassword(id: string, body: ChangePassword){
        const token = await authService.getAccessToken();
        const url = `api/account/${id}/changePassword`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        });

        return await handleResponse<AccountModel>(response, url);
    }

}