import React, { Component } from 'react';
import ReactCrop, {Crop} from 'react-image-crop';
import Dropzone from 'react-dropzone';

import 'react-image-crop/dist/ReactCrop.css';
import {ImageUploadType} from "../../domain/ImageUploadType";
import {getOrientation, resetOrientation} from "../../helper/ImageRotationService";
import {Button, Modal, ModalBody, ModalHeader} from "reactstrap";

interface Props {
    aspectRatio: number;
    onSubmit: (img: ImageUploadType) => void;
    previewWidth: number;
    previewHeight: number;
    minRatio?: number;
    maxRatio?: number;
    onCancel?: () => void;
    fixedAspect: boolean;
}
interface State {
    crop: Crop | undefined;
    fileEnding: string;
    cropsrc: string;
    fixedAspect: boolean;
    isLoading: boolean;
    image: HTMLImageElement | undefined;
    scale: number;
}


class ImageForm extends Component<Props,State>{
    aspect: number;

    constructor(props: Props){
        super(props);
        this.aspect = props.aspectRatio || 16/9;
        this.state = {
            image: undefined,
            crop: undefined,
            cropsrc: '',
            fileEnding: 'unknown',
            fixedAspect: props.fixedAspect,
            isLoading: false,
            scale: 1
        };
    }

    componentDidMount(): void {
        if(this.state.fixedAspect){
            this.setFixedAspect();
        }
    }

    submit = () => {
        if (this.state.crop && this.state.cropsrc && this.state.image){
            const crop = this.state.crop;

            if(crop.x === undefined || crop.y === undefined
                || !crop.width || !crop.height) return;

            let image = new Image();
            image.src = this.state.cropsrc;
            const data = {
                base64Image: this.state.cropsrc,
                x: Math.round(crop.x * this.state.scale) || 0,
                y: Math.round(crop.y * this.state.scale) || 0,
                width: Math.min(
                    Math.round(crop.width * this.state.scale) || 0,
                    this.state.image.naturalWidth
                ),
                height: Math.min(
                    Math.round(crop.height * this.state.scale) || 0,
                    this.state.image.naturalHeight
                ),
                fileEnding: this.state.fileEnding,
                aspectRatio: this.aspect,
                previewBlob: this.getCroppedPreview(image, crop),
                salt: Math.random()
            };

            this.props.onSubmit(data);
        }
    };

    cropChange = (crop: Crop) => {
        if(crop.width === undefined){
            if(this.state.image){
                crop.width = this.state.image.width;
                crop.height = this.state.image.height;
                this.setState({ crop });
            }
        }
        else{
            this.setState({ crop });
        }


    };

    getCroppedPreview = (image: HTMLImageElement, crop: Crop | undefined) => {
        if (!crop ||
            crop.width === undefined ||
            crop.height === undefined ||
            crop.x === undefined ||
            crop.y === undefined
        ){
            return '';
        }

        const canvas = document.createElement('canvas');

        const ctx = canvas.getContext('2d');
        if (ctx === null) return '';

        const currentAspect = crop.width / crop.height;
        let desiredWidth, desiredHeight;
        const isFixedAspect = this.props.previewHeight && this.props.previewWidth;
        const originalAspect = crop.width / crop.height;
        const isSmallerThanMinAspect = this.props.minRatio && this.props.minRatio > originalAspect;
        const isBiggerThanMaxAspect = this.props.maxRatio  && this.props.maxRatio < originalAspect;

        //find the correct aspect for the preview image
        const aspect = isFixedAspect ?
            this.props.aspectRatio :
            isSmallerThanMinAspect ?
                (this.props.minRatio || this.props.aspectRatio) :
                isBiggerThanMaxAspect ?
                    (this.props.maxRatio || this.props.aspectRatio) :
                    crop.width / crop.height;

        canvas.width = this.props.previewWidth;
        canvas.height = this.props.previewHeight;
        //adjust previewWidth if either width/height is auto
        if (this.props.previewHeight === 0)
        {
            canvas.height = Math.round(this.props.previewWidth / aspect);
        }
        else if(this.props.previewWidth === 0)
        {
            canvas.width = Math.round(this.props.previewHeight * aspect);
        }

        //set desired height width factor. defaults to const (1)
        if(isFixedAspect || isSmallerThanMinAspect || isBiggerThanMaxAspect){
            if (currentAspect < aspect)
            {
                desiredHeight = 1;
                const fullPixelHeight = crop.height;
                const fullPixelWidth = fullPixelHeight * aspect;
                desiredWidth = crop.width / fullPixelWidth;
            }
            else
            {
                desiredWidth = 1;
                const fullPixelWidth = crop.width;
                const fullPixelHeight = fullPixelWidth / aspect;
                desiredHeight = crop.height / fullPixelHeight;
            }
        }

        const x = (1 - (desiredWidth || 1)) / 2 * canvas.width;
        const y = (1 - (desiredHeight || 1)) / 2 * canvas.height;


        ctx.drawImage(
            image,
            Math.floor(crop.x * this.state.scale),
            Math.floor(crop.y * this.state.scale),
            Math.floor(crop.width * this.state.scale),
            Math.floor(crop.height * this.state.scale),
            Math.floor(x),
            Math.floor(y),
            Math.floor((desiredWidth || 1) * canvas.width),
            Math.floor((desiredHeight || 1) * canvas.height)
        );


        return canvas.toDataURL('image/png');
    };

    setMaxCropSize = () => {
        if(this.state.image){
            const crop: Crop = {
                ...this.state.crop,
                x: 0,
                y: 0,
                height: this.state.image.height,
                width: this.state.image.width,
            };

            this.setState({
                crop: crop
            });
        }
    };

    onImageLoaded = (image: HTMLImageElement) => {
        let crop: Crop = {
            x: 0,
            y: 0,
            width: image.width,
            height: image.height
        };

        if(this.state.fixedAspect){
            crop = {...crop, aspect: this.aspect};
        }
        const scale = image.naturalWidth / image.width;

        this.setState({
            image: image,
            crop: crop,
            isLoading: false,
            scale: scale
        });
    };

    setFixedAspect = () => {
        const x = this.state.crop && this.state.crop.x ? this.state.crop.x : 0;
        let height = this.state.crop && this.state.crop.height;
        let width = this.state.crop && this.state.crop.width;

        //calculate new cut
        if(this.state.image && this.state.crop && this.state.crop.width && this.state.crop.height){
            height = this.state.crop.height;
            width = height * this.aspect;
            while(width + x > this.state.image.width){
                height = height * 0.98;
                width = height * this.aspect;
            }

        }

        let crop: Crop = {
            x: (this.state.crop && this.state.crop.x) || 0,
            y: (this.state.crop && this.state.crop.y) || 0,
            height: height,
            width: width,
            aspect: this.aspect,
        };

        this.setState({
            crop: crop
        });
    };

    fileDropped = (acceptedFiles: File[]) => {
        const reader = new FileReader();
        let file = acceptedFiles[0];
        if (file){
            reader.onload = (upload) => {
                const target = (upload.target as FileReader);
                getOrientation(file, (degree) => {
                    resetOrientation(target.result, degree, (blob) =>{
                        this.setState({ cropsrc : blob.toString(), fileEnding: file.name.split(".").pop() || 'unknown'});
                    })
                });

            };
            reader.readAsDataURL(file);
            this.setState({
                isLoading: true
            });
        }
    };


    render(){
        let disable = !this.state.crop || !this.state.cropsrc;
        return (
            <Modal isOpen={true} backdrop={true} toggle={this.props.onCancel}>
                <ModalHeader toggle={this.props.onCancel}>
                    Create Text
                </ModalHeader>
                <ModalBody>
                    <div className='image-form'>
                        { this.state.isLoading && <div>Loading...</div>}
                        <h2>Image</h2>
                        <div className='drop-container'>
                            <div>
                                <Dropzone onDrop={this.fileDropped} accept='image/*'>
                                    {({ getRootProps, getInputProps, isDragActive }) => {
                                        return (
                                            <div {...getRootProps()} className={`drop-zone${isDragActive ? ' active' : ''} `}>
                                                <input {...getInputProps()} />
                                                <p>Drag and drop or click here to upload images</p>
                                            </div>
                                        )
                                    }}
                                </Dropzone>
                            </div>
                        </div>
                        {this.state.cropsrc &&
                        <div>
                            <Button onClick={() => this.setMaxCropSize()}>Set max crop</Button>
                        </div>
                        }
                        <div className='crop-container'>
                            {this.state.cropsrc &&
                            <ReactCrop src={this.state.cropsrc} onChange={this.cropChange} crop={this.state.crop}  onImageLoaded={this.onImageLoaded} />
                            }
                        </div>
                        <div className='btn-split-container'>
                            <Button onClick={this.submit} disabled={disable}>Upload</Button>
                            {this.props.onCancel && <Button onClick={this.props.onCancel}>Cancel</Button>}
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        );
    }
}

export default ImageForm;