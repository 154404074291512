import React, {Component} from 'react';
import {SoundUploadType} from "../../domain/SoundUploadType";
import {FormGroup} from "reactstrap";

interface Props{
    onSubmit: (s: SoundUploadType) => void;
    label: string;
}
interface State{
    sound: string | null;
    soundFile: File | null;
    canRecord: boolean;
}

export default class SoundFormGroup extends Component<Props, State>{

    constructor(props: Props){
        super(props);
        this.state = {
            sound: null,
            soundFile: null,
            canRecord: false,
        };

    }

    filesUploaded = (files: FileList | null) => {
        if(!files) return;
        let file = files[0];
        if (file){
            
            const src = URL.createObjectURL(file);
            this.setState({
                ...this.state,
                sound: src,
                soundFile: file
            });

            const data = {
                soundFile: file,
                sound: src,
                salt: Math.floor(Math.random()*Math.floor(999999999))
            };

            this.props.onSubmit(data);
        }
    };

    render(){
        return (
            <FormGroup>
                <label>{this.props.label}</label>
                <p className='text-muted'>The audio should be either: .ogg or .wav format.</p>
                <div>
                    <input type='file' onChange={e => this.filesUploaded(e.target.files)} accept='.wav,.ogg' />
                </div>

                <div className='sound-container'>
                    {this.state.sound &&
                        <audio controls>
                            <source src={this.state.sound} />
                        </audio>
                    }
                </div>
            </FormGroup>
        );
    }
}