import * as React from 'react';
import {Component} from 'react';
import {Redirect} from "react-router";
import authService, {AuthorizeService} from "./AuthorizeService";

interface Props{}
interface State{
    ready: boolean;
}

export default class Logout extends Component<Props, State>{
    private _authService: AuthorizeService;
    private _subscription!: number;

    constructor(props: Props){
        super(props);
        this._authService = authService;

        this.state = {
            ready: false
        }
    }

    componentDidMount(): void {
        this._subscription = authService.subscribe(() => this.authenticationChanged());
        this._authService.signOut().then();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    async authenticationChanged() {
        if(!await this._authService.isAuthenticated()){
            this.setState({ ready: true });
        }
    }


    render(){
        if(this.state.ready){
            return <Redirect to={"/login"} />
        }
        else{
            return <p>Logging out...</p>
        }


    }
}