import * as React from 'react';
import {ChangeEvent, Component} from 'react';
import {Badge, Button, CardColumns, Col, FormGroup, Modal, ModalBody, ModalHeader, Row, Table} from "reactstrap";
import {GameFlow, PostGameFlow} from "../../domain/GameFlow";
import {FormEvent} from "react";
import {RouteComponentProps} from "react-router";
import {GameFlowItem} from "../../domain/GameFlowItem";
import {GameTSType, GameType} from "../../domain/GameType";
import {BaseGame} from "../../domain/BaseGame";
import {SortByTime} from "../../helper/SortFunctions";
import {toDoubleDigit} from "../../helper/StringFormat";
import {GameFlowData} from "../../data/GameFlowData";
import {GameData} from "../../data/GameData";
import {isResponseError, ResponseError} from "../../domain/ResponseError";
import {Link} from "react-router-dom";
import {ActiveGameFlow} from "../../domain/ActiveGameFlow";
import GameCard from "../shared/GameCard";
import moment from "moment";
import {MuiPickersUtilsProvider, TimePicker} from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import 'date-fns';

interface MatchParams{
    id: string;
}
interface Props extends RouteComponentProps<MatchParams>{}

interface State{
    gameFlow: GameFlow;
    gameFlowItem: GameFlowItem;
    loading: boolean;
    error: false|string;
    games: Array<GameTSType>;
    deleteSure: false|string;
    usedGames: {[id: string]: BaseGame}
    activeGameFlow: ActiveGameFlow | null;
    chooseGameModal: boolean;
    selectedGame: GameTSType | null;
}

export default class GameFlowCreate extends Component<Props, State>{
    _gameFlowStore = new GameFlowData();
    _gameStore = new GameData();

    _gameTypes: Array<{value:GameType, name: string}> = [
        {name: "None", value: "None"},
        {name: "Scratch", value: "Scratch"},
        {name: "Sakura", value: "Sakura"},
        {name: "Collage", value: "Collage"},
        {name: "Hearts", value: "Hearts"},
        {name: "RoboDance", value: "RoboDance"},
        {name: "FishTank", value: "FishTank"}
    ];

    constructor(props: Props) {
        super(props);
        const id = this.props.match.params.id;

        this.state = {
            gameFlow: {
                id: id || '',
                name: '',
                flow: []
            },
            gameFlowItem: {
                id: '',
                gameId: '',
                gameType: 'None',
                hours: 0,
                minutes: 0
            },
            activeGameFlow: null,
            loading: false,
            error: false,
            games: [],
            deleteSure: false,
            usedGames: {},
            chooseGameModal: false,
            selectedGame: null
        };

        this.handleGameFlowResponse = this.handleGameFlowResponse.bind(this);
    }

    componentDidMount(): void {
        if(this.state.gameFlow.id){
            this.getGameFlow(this.state.gameFlow.id).then();
            this._gameFlowStore.getActiveFlow().then(x => {
                if(!isResponseError(x)){
                    this.setState({activeGameFlow: x});
                }
            })
        }
    }

    getGameName(gameType: string, gameId: string){
        this._gameStore.getGame<BaseGame>(gameType, gameId).then(data => {
            if(!isResponseError(data)){
                this.setState({ usedGames: {...this.state.usedGames, [data.id]: data}});
            }
        });
    }

    getGames(gameType: string){
        this._gameStore.getGames<GameTSType>(gameType).then(data => {
            if(!isResponseError(data)){
                this.setState({ games: data});
            }
        });
    }

    async getGameFlow(id: string){
        this.setState({loading: true});
        this._gameFlowStore.getGameFlow(id).then(x => {
            if(!isResponseError(x)){
                this.handleGameFlowResponse(x);
            }
            else{
                this.setState({loading: false, error: x.errorMsg})
            }
        })
    }

    async postGameFlow(){
        this.setState({loading: true});
        const body:PostGameFlow = {
            name: this.state.gameFlow.name
        };
        this._gameFlowStore.postGameFlow(body).then(x => {
            this.handleGameFlowResponse(x);
        });
    }

    async putGameFlow(){
        this.setState({loading: true});

        const body:PostGameFlow = {
            name: this.state.gameFlow.name
        };

        this._gameFlowStore
            .putGameFlow(body, this.state.gameFlow.id)
            .then(this.handleGameFlowResponse);
    }

    async postGameFlowItem(){
        this.setState({loading: true});
        const body = {
            gameId: this.state.gameFlowItem.gameId,
            gameType: this.state.gameFlowItem.gameType,
            hours: this.state.gameFlowItem.hours,
            minutes: this.state.gameFlowItem.minutes
        };
        this._gameFlowStore
            .postGameFlowItem(body, this.state.gameFlow.id)
            .then(this.handleGameFlowResponse);
    }

    async deleteFlowItem(id: string){
        if(this.state.deleteSure !== id){
            this.setState({deleteSure: id});
            return;
        }

        this._gameFlowStore
            .deleteFlowItem(this.state.gameFlow.id, id)
            .then(this.handleGameFlowResponse);
    }

    handleGameFlowResponse(x: GameFlow|ResponseError){
        if(!isResponseError(x)){
            this.setState({loading: false, gameFlow: x});
            this.state.gameFlow.flow && this.state.gameFlow.flow.forEach(x => this.getGameName(x.gameType, x.gameId));
        }
        else{
            this.setState({loading: false, error: x.errorMsg})
        }
    }

    render(){
        const onSubmit = (e: FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            if(this.state.gameFlow.id){
                this.putGameFlow().then();
            }
            else{
                this.postGameFlow().then();
            }
        };

        const onSubmitItem = (e: FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            if(this.state.gameFlow.id){
                this.postGameFlowItem().then();
            }
        };

        const onGameTypeChange = (e:ChangeEvent<HTMLSelectElement>) => {
            this.getGames(e.target.value);
            this.setState({
                gameFlowItem: {
                    ...this.state.gameFlowItem,
                    gameType: e.target.value as GameType,
                    gameId: ''
                },
                chooseGameModal: true,
                selectedGame: null
            });
        };

        const hours = [];
        for (let i = 0; i < 24; i++){
            hours.push(toDoubleDigit(i));
        }
        const minutes = [];
        for (let i = 0; i < 60; i += 5){
            minutes.push(toDoubleDigit(i));
        }

        return(
            <div>
                <h1>
                    Gameflow
                    {this.state.activeGameFlow && this.state.activeGameFlow.gameFlowId === this.state.gameFlow.id &&
                    <Badge color={'success'} style={{marginLeft: "15px"}}>Live</Badge>
                    }
                </h1>


                <Row>
                    <Col md={9}>
                        <form onSubmit={onSubmit}>
                            <div className='form-group'>
                                <label htmlFor='name'>Name</label>
                                <input className='form-control' type='text' id='name' name='name' value={this.state.gameFlow.name} onChange={(e) => this.setState({gameFlow: {...this.state.gameFlow, name: e.target.value}})} />
                            </div>
                            <div className='form-group'>
                                <Button type='submit' disabled={this.state.loading}>{this.state.gameFlow.id ? 'Update' : 'Create'}</Button>
                            </div>
                        </form>
                        <div>
                            <h3>Flow</h3>
                            <Table>
                                <thead>
                                <tr>
                                    <th>Time</th>
                                    <th>GameType</th>
                                    <th>Name</th>
                                    <th>Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    this.state.gameFlow.flow &&
                                    this.state.gameFlow.flow.sort(SortByTime).map(x => {
                                    const g = this.state.usedGames[x.gameId];
                                    return(
                                        <tr key={x.id}>
                                            <td>
                                                {`${toDoubleDigit(x.hours)}:${toDoubleDigit(x.minutes)}`}
                                            </td>
                                            <td>{x.gameType}</td>
                                            <td>
                                                {g ? <Link to={`/${g.gameType}/edit/${g.id}`}>
                                                    {g.name}
                                                </Link> : "Unknown"}
                                            </td>
                                            <td>
                                                <Button
                                                    color='danger'
                                                    size='sm'
                                                    onClick={() => this.deleteFlowItem(x.id)}
                                                >
                                                    {this.state.deleteSure === x.id ? 'are you sure?' : 'delete' }
                                                </Button>
                                            </td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                    {this.state.gameFlow.id &&
                        <Col md={3}>
                            <form onSubmit={onSubmitItem}>
                                <h2>Add game to flow</h2>
                                <FormGroup>
                                    <label htmlFor='hours'>Time</label>
                                    <div>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <TimePicker
                                                ampm={false}
                                                value={moment().set("hours", this.state.gameFlowItem.hours).set("minutes", this.state.gameFlowItem.minutes).toDate()}
                                                onChange={(date) => this.setState({
                                                    gameFlowItem: {
                                                        ...this.state.gameFlowItem,
                                                        hours: date ? date.getHours() : 0,
                                                        minutes: date ? date.getMinutes() : 0
                                                    }
                                                })}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                </FormGroup>
                                <FormGroup>
                                    <label htmlFor='gameType'>GameType</label>
                                    <select
                                        className='form-control'
                                        id='gameType'
                                        name='gameType'
                                        value={this.state.gameFlowItem.gameType}
                                        onChange={onGameTypeChange}
                                    >
                                        {this._gameTypes.map(i =>
                                            <option key={i.value} value={i.value}>{i.name}</option>
                                        )}
                                    </select>
                                </FormGroup>
                                {this.state.gameFlowItem.gameType !== "None" &&
                                    <FormGroup>
                                        <label>Game</label>
                                        <div>
                                            <GameCard
                                                className={'selected-game'}
                                                game={this.state.selectedGame}
                                            />
                                        </div>
                                        <Button onClick={() => this.setState({chooseGameModal: true})}>Choose game</Button>
                                    </FormGroup>
                                }
                                <FormGroup>
                                    <Button type='submit' disabled={this.state.loading}>Add</Button>
                                </FormGroup>

                                {
                                this.state.gameFlowItem.gameType !== "None" &&
                                this.state.loading ? 'Loading...' :
                                    <Modal className='game-choose-modal' isOpen={this.state.chooseGameModal} backdrop={true} toggle={() => this.setState({chooseGameModal: false})}>
                                        <ModalHeader toggle={() => this.setState({chooseGameModal: false})}>
                                            Choose game
                                        </ModalHeader>
                                        <ModalBody>
                                            <CardColumns style={{columnCount: 5}}>
                                                {
                                                    this.state.games.map(x =>
                                                        <GameCard
                                                            onClick={() => this.setState({
                                                                gameFlowItem: {
                                                                    ...this.state.gameFlowItem,
                                                                    gameType: x.gameType,
                                                                    gameId: x.id
                                                                },
                                                                chooseGameModal: false,
                                                                selectedGame: x
                                                            })}
                                                            game={x}
                                                        />
                                                    )
                                                }
                                            </CardColumns>
                                        </ModalBody>
                                    </Modal>
                                }
                            </form>
                        </Col>
                    }
                </Row>
            </div>
        )
    }
}