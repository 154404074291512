import React from 'react';
import {Button, FormGroup} from "reactstrap";

interface Props{
    disabled: boolean;
    isCreate: boolean;
}

const SubmitButton = (props: Props) => {
    return(
        <>
            {props.disabled && <div>Loading...</div>}
            <FormGroup>
                <Button disabled={props.disabled} color='success' type='submit'>{props.isCreate ? 'Create' : 'Update'}</Button>
            </FormGroup>
        </>
    )
};

export default SubmitButton;