import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {Alert, Button, Table} from "reactstrap";
import {AccountData} from "../../data/AccountData";
import {isResponseError, ResponseError} from "../../domain/ResponseError";
import {AccountModel} from "../../domain/AccountModel";
interface Props{

}
interface State{
    accounts: AccountModel[];
    loading: boolean;
    error: ResponseError | false;
    deleteSure: false|string;
}

export class AccountList extends Component<Props, State>{

    _accountData = new AccountData();

    constructor(props: Props){
        super(props);
        this.state = {
            accounts: [],
            loading: false,
            deleteSure: false,
            error: false,
        }
    }

    componentDidMount(): void {
        this.setState({loading: true});

        this._accountData.getAll().then(x => {
            if(!isResponseError(x)){
                this.setState({accounts: x, loading: false});
            }
        })
    }

    delete(id: string){
        if(this.state.deleteSure !== id){
            this.setState({deleteSure: id});
            return;
        }

        this._accountData.delete(id).then(x => {
            if(!isResponseError(x)){
                this.setState({accounts: this.state.accounts.filter(a => a.id !== id)});
            }
        });
    }

    render(){
        return(
            <div>
                <h1>Accounts</h1>
                <div>
                    <Link to="/account/create">
                        <Button className={'mb-2'} color='primary'>
                            Create new
                        </Button>
                    </Link>
                </div>
                { this.state.error && <Alert color='danger'>{this.state.error.errorMsg}</Alert>}
                { this.state.loading ? <p>Loading...</p> :
                    <section>
                        <Table>
                            <thead>
                            <tr>
                                <th>Email</th>
                                <th>Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.accounts.map(x => {
                                return(
                                    <tr key={x.id}>
                                        <td>{x.email}</td>
                                        <td>
                                            <div className='btn-group-custom'>
                                                <Link to={`/account/edit/${x.id}`}>
                                                    <Button color='info' size='sm'>edit</Button>
                                                </Link>
                                                <Link to={`/account/changepass/${x.id}`}>
                                                    <Button color='warning' size='sm'>change password</Button>
                                                </Link>
                                                <Button
                                                    color='danger'
                                                    size='sm'
                                                    onClick={() => this.delete(x.id)}
                                                >
                                                    {this.state.deleteSure === x.id ? 'are you sure?' : 'delete' }
                                                </Button>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}

                            </tbody>
                        </Table>
                    </section>
                }
            </div>
        )
    }
}