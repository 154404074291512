import {TokenResponse} from "../../domain/TokenResponse";
import moment from "moment";

const tokenKey = "happyjwt";
const expireTimeKey = "happyjwt_expire";

export class AuthorizeService {
    private static _instance: AuthorizeService;

    _callbacks: Array<{callback: () => void, subscription: number}> = [];
    _nextSubscriptionId: number = 0;
    _tokenResponse: TokenResponse|null = null;
    _reAuthInProgress: boolean = false;

    constructor(){
        const token = localStorage.getItem(tokenKey) || null;
        const expireTime = localStorage.getItem(expireTimeKey) || null;

        if(token && expireTime){
            this.updateState({token, expireTime});
        }

        setInterval(() => {
            this.refreshTokenIfCloseToExpire().then();
        }, 250000)
    }

    isAuthenticated() {
        if(this._tokenResponse){
            const expireTime = moment.utc(this._tokenResponse.expireTime);
            if(expireTime){
                return moment().isBefore(expireTime);
            }
        }

        return false;
    }


    async getAccessToken() {
        if(this._tokenResponse){
            const token = this._tokenResponse.token;
            const expireTime = moment.utc(this._tokenResponse.expireTime);
            if(moment().isBefore(expireTime)){
                return token;
            }
        }
        this.notifySubscribers();
        return null;
    }

    async refreshTokenIfCloseToExpire(){
        if(this._tokenResponse){
            const expireTime = moment.utc(this._tokenResponse.expireTime);
            const now = moment.utc();
            const nowPlus10min = moment.utc().add('10', 'minutes');
            if(now.isBefore(expireTime) && nowPlus10min.isAfter(expireTime)){
                console.log("Renewing token");
                this.refreshToken().then();
                return;
            }
            if(now.isAfter(expireTime)){
                console.log("Token to old, signing out...");
                this.signOut();
                return;
            }
            console.log(`Token expires ${expireTime.fromNow()}`);
        }
    }

    async refreshToken(){
        if(!this._reAuthInProgress){
            this._reAuthInProgress = true;
            const response = await fetch('api/auth/ReAuthenticate', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${await this.getAccessToken()}`,
                    'Content-Type': 'application/json'
                }
            });
            const tokenResponse: TokenResponse = await response.json();
            this.updateState(tokenResponse);
            this._reAuthInProgress = false;
        }
    }


    async signIn(username: string, password: string) {
        const response = await fetch('api/auth/authenticate', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({userName: username, password: password})
        });
        const tokenResponse: TokenResponse = await response.json();
        this.updateState(tokenResponse);
    }

    async signOut() {
        localStorage.removeItem(tokenKey);
        localStorage.removeItem(expireTimeKey);
        this.updateState(null);
    }


    updateState(tokenResponse: TokenResponse|null) {
        if(tokenResponse){
            localStorage.setItem(tokenKey, tokenResponse.token);
            localStorage.setItem(expireTimeKey, tokenResponse.expireTime);
        }
        this._tokenResponse = tokenResponse;
        this.notifySubscribers();
    }

    subscribe(callback: () => void) {
        this._callbacks.push({ callback, subscription: this._nextSubscriptionId++ });
        return this._nextSubscriptionId - 1;
    }

    unsubscribe(subscriptionId?: number) {
        this._callbacks = this._callbacks.filter(x => x.subscription !== subscriptionId);
    }

    notifySubscribers() {
        for (let i = 0; i < this._callbacks.length; i++) {
            const callback = this._callbacks[i].callback;
            callback();
        }
    }

    static get instance(): AuthorizeService {
        if(!this._instance){
            this._instance = new AuthorizeService();
        }

        return this._instance;
    }
}

const authService = AuthorizeService.instance;

export default authService;
