import {DbImage} from "./DbImage";
import {UiText} from "./UiText";
import {BaseGame} from "./BaseGame";

export interface Scratch extends BaseGame{
    foreground: DbImage | null;
    background: DbImage | null;
    uiText: UiText | null;
    textOnForeground: boolean;
}

export function isScratch(possible: any): possible is Scratch{
    const game = possible as Scratch;
    if(game.gameType === "Scratch")
    {
        return true;
    }
    return false;
}