import * as React from 'react';
import { Component } from 'react';
import {Link} from "react-router-dom";
import {Button, Table} from "reactstrap";
import authService from "../../api-authorization/AuthorizeService";
import {Scratch} from "../../../domain/Scratch";
import {DbOrUploadImage} from "../../../helper/ImageHelper";

interface Props{

}

interface State{
    games: Array<Scratch>,
    loadingGames: boolean;
    deleteSure: false|string;
}

export class ScratchIndex extends Component<Props,State>{

    constructor(props: Props){
        super(props);
        this.state = {
            games: [],
            loadingGames: true,
            deleteSure: false
        }
    }

    componentDidMount(): void {
        this.getGames().then();
    }

    async getGames(){
        const token = await authService.getAccessToken();
        const response = await fetch('api/scratch', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        this.setState({ games: data, loadingGames: false });
    }

    async deleteGames(gameId: string){
        if(this.state.deleteSure !== gameId){
            this.setState({deleteSure: gameId});
            return;
        }
        const token = await authService.getAccessToken();
        const response = await fetch(`api/scratch/${gameId}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        });
        if(response.status === 200){
            this.setState({ games: this.state.games.filter(x => x.id !== gameId)});
        }
    }

    render(){
        return(
            <div>
                <h1>Scratch Games</h1>
                <Link to="/scratch/create">
                    <Button className={'mb-2'} color='primary'>
                        Create new
                    </Button>
                </Link>

                { this.state.loadingGames ? <p>Loading...</p> :
                    <section>
                        <Table>
                            <thead>
                            <tr>
                                <th>Name</th>
                                <th>Foreground</th>
                                <th>Background</th>
                                <th>Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.games.map(x => {
                                return(
                                    <tr key={x.id}>
                                        <td>{x.name}</td>
                                        <td><DbOrUploadImage image={x.foreground} /></td>
                                        <td><DbOrUploadImage image={x.background} /></td>
                                        <td>
                                            <div className='btn-group-custom'>
                                                <Link to={`/scratch/edit/${x.id}`}> <Button color='info' size='sm'>edit</Button></Link>
                                                <Button
                                                    color='danger'
                                                    size='sm'
                                                    onClick={() => {this.deleteGames(x.id).then()}}
                                                >
                                                    {this.state.deleteSure === x.id ? 'are you sure?' : 'delete' }
                                                </Button>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}

                            </tbody>
                        </Table>
                    </section>
                }
            </div>
        )
    }
}