import {BaseGame} from "./BaseGame";
import {DbSound} from "./DbSound";
import {UiText} from "./UiText";

export interface RoboDance extends BaseGame{
    type: RoboDanceRoboType;
    musicFile: DbSound;
    uiText: UiText;
}

export type RoboDanceRoboType =
    | "Elephant"
    | "Robot"
    | "Astronaut"
    | "Monster"
    | "Girl"
    | "Doctor";


export function isRoboDance(possible: any): possible is RoboDance{
    const game = possible as RoboDance;
    if(game.gameType === "RoboDance")
    {
        return true;
    }
    return false;
}