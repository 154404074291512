import {BaseGame} from "./BaseGame";
import {DbImage} from "./DbImage";
import {UiText} from "./UiText";
import {SakuraRefillType} from "./Sakura";

export interface Collage extends BaseGame{
    objects: DbImage[];
    background: DbImage;
    uiText: UiText | null;
    refillType: SakuraRefillType;
}

export function isCollage(possible: any): possible is Collage{
    const game = possible as Collage;
    if(game.gameType === "Collage")
    {
        return true;
    }
    return false;
}