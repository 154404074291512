import * as React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlay} from "@fortawesome/free-solid-svg-icons";
import {faStop} from "@fortawesome/free-solid-svg-icons";
import {SoundUploadType} from "../domain/SoundUploadType";
import {DbSound} from "../domain/DbSound";
import {ImageUploadType} from "../domain/ImageUploadType";
import {DbImage} from "../domain/DbImage";

export function isDbImage(image: ImageUploadType | DbImage | null): image is DbImage{
    return !!image && 'id' in image;
}
export function isDbAudio(audio: SoundUploadType | DbSound | null): audio is DbSound {
    return !!audio && 'id' in audio;
}

export const getImgSrc = (image: ImageUploadType | DbImage | null | false, realSize: boolean) => {
    if (!image) return false;
    if (!realSize){
        return isDbImage(image) ? image.thumbPath : image.previewBlob;
    } 
    else{
        return isDbImage(image) ? image.imagePath : image.previewBlob;
    }
};

interface DbOrUploadImageProps {
    image: DbImage | ImageUploadType | null | false;
    alt?: string;
    className?: string;
    realSize?: boolean;
    tempImage?: string;
    style?: React.CSSProperties;
}
export const DbOrUploadImage = (props: DbOrUploadImageProps) => {
    const imgSrc = getImgSrc(props.image, !!props.realSize) || props.tempImage || null;
    if (!imgSrc) return null;
    return(
        <img src={imgSrc} alt={props.alt || ''} className={props.className || ''} style={props.style || undefined}/>
    )
};

export const getAudioSrc = (audio: DbSound | null | false) => {
    return audio ? audio.soundPath : '';
};

interface DbOrUploadSoundProps {
    audio: DbSound | null | false;
    alt?: string;
    className?: string;
    children?: React.ReactNode;
}

export const DbOrUploadSound = (props: DbOrUploadSoundProps) => {
    const audioSrc = getAudioSrc(props.audio);
    if(!audioSrc) return null;

    let sound = document.createElement('audio');
    sound.src = audioSrc;

    const playSound = (e: React.MouseEvent<SVGSVGElement>) => {
        e.stopPropagation();
        sound.pause();
        sound.currentTime = 0;
        sound.play();
    };

    const stopSound = (e: React.MouseEvent<SVGSVGElement>) => {
        e.stopPropagation();
        sound.pause();
    };

    return(
        <div className={props.className}>
            <FontAwesomeIcon className='audio-control' icon={faPlay} onClick={playSound} />
            <FontAwesomeIcon className='audio-control' icon={faStop} onClick={stopSound} />
            {props.children}
        </div>
    )
};