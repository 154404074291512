import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import {Link, NavLink as RRNavLink} from 'react-router-dom';
import './NavMenu.css';
import authService, {AuthorizeService} from "./api-authorization/AuthorizeService";

interface Props{

}
interface State{
  collapsed: boolean;
  authenticated: boolean;
}

export class NavMenu extends Component<Props, State> {
  private _authService: AuthorizeService;
  private _subscription!: number;

  constructor (props: Props) {
    super(props);

    this._authService = authService;

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true,
      authenticated: false
    };
  }
  componentDidMount() {
    this._subscription = authService.subscribe(() => this.authenticationChanged());
    this.setState({authenticated: this._authService.isAuthenticated()})
  }

  componentWillUnmount() {
    authService.unsubscribe(this._subscription);
  }

  authenticationChanged() {
    this.setState({ authenticated: this._authService.isAuthenticated() });
  }

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render () {
    return (
      <header>
        <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-4" light>
          <Container>
            <NavbarBrand tag={Link} to="/">ActiveHall</NavbarBrand>
            <NavbarToggler onClick={this.toggleNavbar} className="mb-4" />
            <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
              <ul className="navbar-nav flex-grow">
                <NavItem >
                  <NavLink tag={RRNavLink} activeClassName='active' className="text-dark" to="/account">Account</NavLink>
                </NavItem>
                <NavItem >
                  <NavLink tag={RRNavLink} activeClassName='active' className="text-dark" to="/gameflow">GameFlow</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={RRNavLink} activeClassName='active'  className="text-dark" to="/scratch">Scratch</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={RRNavLink} activeClassName={'active'}  className="text-dark" to="/sakura">Sakura</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={RRNavLink} activeClassName='active'   className="text-dark" to="/collage">Collage</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={RRNavLink} activeClassName='active' className="text-dark" to="/hearts">Hearts</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={RRNavLink} activeClassName='active' className="text-dark" to="/robodance">RoboDance</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={RRNavLink} activeClassName='active' className="text-dark" to="/fishtank">FishTank</NavLink>
                </NavItem>
                {this.state.authenticated ?
                    <NavItem>
                      <NavLink tag={RRNavLink} activeClassName='active' className="text-dark" to="/logout">Logout</NavLink>
                    </NavItem>
                    :
                    <NavItem>
                      <NavLink tag={RRNavLink} activeClassName='active' className="text-dark" to="/login">Login</NavLink>
                    </NavItem>
                }
              </ul>
            </Collapse>
          </Container>
        </Navbar>
      </header>
    );
  }
}
