import React, {ReactNode} from 'react';
import {Alert} from "reactstrap";


export type ApiErrors = {[key: string]: Array<string>};

interface Props{
    errors: ApiErrors;
}

export const handleFetchError = async (response: Response): Promise<ReactNode> => {
    if (response.status === 400) {
        const errors = (await response.json()).errors;
        return <FormErrors errors={errors}/>;
    } else {
        return <Alert color='danger'>{`${response.status} ${response.statusText}`}</Alert>;
    }
};

const FormErrors = (props: Props) => {

    const arrKeys = Object.keys(props.errors);

    return(
        <Alert color='danger'>
            {arrKeys.map(k => {
                return(
                    <div key={k}>
                        {props.errors[k].map(e => <div key={e}>{e}</div>)}
                    </div>
                )
            })}
        </Alert>
    )
};

export default FormErrors;