import authService from "../components/api-authorization/AuthorizeService";
import {GameFlow, PostGameFlow} from "../domain/GameFlow";
import {handleResponse} from "../helper/ResponseHelper";
import {ActiveGameFlow} from "../domain/ActiveGameFlow";
import {CreateGameFlowItem} from "../domain/GameFlowItem";

export class GameFlowData{
    async getGameFlows(){
        const token = await authService.getAccessToken();
        const url = 'api/gameflow';
        const response = await fetch(url, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        return await handleResponse<GameFlow[]>(response, url);
    }

    async deleteGameFlow(id: string){
        const token = await authService.getAccessToken();
        const url = `api/gameflow/${id}`;
        const response = await fetch(url, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        });
        return await handleResponse<{}>(response, url);
    }

    async getGameFlow(id: string){
        const token = await authService.getAccessToken();
        const url = `api/gameflow/${id}`;
        const response = await fetch(url, {
            method: 'GET',
            headers: { 'Authorization': `Bearer ${token}` }
        });

        return await handleResponse<GameFlow>(response, url);
    }

    async postGameFlow(body: PostGameFlow){
        const token = await authService.getAccessToken();
        const url = 'api/gameflow';
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        });

        return await handleResponse<GameFlow>(response, url);
    }

    async putGameFlow(body: PostGameFlow, id: string){
        const token = await authService.getAccessToken();
        const url = `api/gameflow/${id}`;
        const response = await fetch(url, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        });

        return await handleResponse<GameFlow>(response, url);
    }

    async postGameFlowItem(body: CreateGameFlowItem, gameFlowId: string){
        const token = await authService.getAccessToken();
        const url = `api/gameflow/${gameFlowId}/flow`;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        });
        return await handleResponse<GameFlow>(response, url);
    }

    async deleteFlowItem(gameFlowId: string, gameFlowItemId: string){
        const token = await authService.getAccessToken();
        const url = `api/gameflow/${gameFlowId}/flow/${gameFlowItemId}`
        const response = await fetch(url, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        });

        return await handleResponse<GameFlow>(response, url);
    }


    async getActiveFlow(){
        const token = await authService.getAccessToken();
        const url = 'api/gameflow/active';
        const response = await fetch(url, {
            headers: { 'Authorization': `Bearer ${token}` }
        });
        return await handleResponse<ActiveGameFlow>(response, url);
    }

    async setActiveFlow(id: string){
        const token = await authService.getAccessToken();
        const url = `api/gameflow/active/${id}`
        const response = await fetch(url, {
            method: "POST",
            headers: { 'Authorization': `Bearer ${token}` }
        });
        return await handleResponse<ActiveGameFlow>(response, url);
    }


}
