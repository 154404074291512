import * as React from 'react';
import {Component} from 'react';
import {Layout} from './components/Layout';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import {SakuraList} from "./components/games/sakura/SakuraList";
import './custom.css'
import {SakuraCreate} from "./components/games/sakura/SakuraCreate";
import {ScratchIndex} from "./components/games/scratch/ScratchIndex";
import {ScratchCreate} from "./components/games/scratch/ScratchCreate";
import {Route} from "react-router";
import Login from "./components/api-authorization/Login";
import Logout from "./components/api-authorization/Logout";
import GameFlowIndex from "./components/gameflow/GameFlowIndex";
import GameFlowCreate from "./components/gameflow/GameFlowCreate";
import {CollageList} from "./components/games/collage/CollageList";
import {CollageCreate} from "./components/games/collage/CollageCreate";
import {HeartsList} from "./components/games/hearts/HeartsList";
import {HeartsCreate} from "./components/games/hearts/HeartsCreate";
import {RoboDanceList} from "./components/games/robodance/RoboDanceList";
import {RoboDanceCreate} from "./components/games/robodance/RoboDanceCreate";
import {FishTankList} from "./components/games/fishtank/FishTankList";
import {FishTankCreate} from "./components/games/fishtank/FishTankCreate";
import Home from "./components/Home";
import {AccountList} from "./components/account/AccountList";
import AccountCreate from "./components/account/AccountCreate";
import AccountEdit from "./components/account/AccountEdit";
import AccountChangePassword from "./components/account/AccountChangePassword";


export default class App extends Component{

    render() {
        return (
            <Layout>
                <AuthorizeRoute exact path='/' component={Home}/>

                <AuthorizeRoute exact path='/account' component={AccountList}/>
                <AuthorizeRoute exact path='/account/create' component={AccountCreate}/>
                <AuthorizeRoute exact path='/account/edit/:id' component={AccountEdit}/>
                <AuthorizeRoute exact path='/account/changepass/:id' component={AccountChangePassword}/>

                <AuthorizeRoute exact path='/scratch' component={ScratchIndex}/>
                <AuthorizeRoute exact path='/scratch/create' component={ScratchCreate}/>
                <AuthorizeRoute exact path='/scratch/edit/:id' component={ScratchCreate}/>

                <AuthorizeRoute exact path='/sakura' component={SakuraList}/>
                <AuthorizeRoute exact path='/sakura/create' component={SakuraCreate}/>
                <AuthorizeRoute exact path='/sakura/edit/:id' component={SakuraCreate}/>

                <AuthorizeRoute exact path='/collage' component={CollageList}/>
                <AuthorizeRoute exact path='/collage/create' component={CollageCreate}/>
                <AuthorizeRoute exact path='/collage/edit/:id' component={CollageCreate}/>

                <AuthorizeRoute exact path='/hearts' component={HeartsList}/>
                <AuthorizeRoute exact path='/hearts/create' component={HeartsCreate}/>
                <AuthorizeRoute exact path='/hearts/edit/:id' component={HeartsCreate}/>

                <AuthorizeRoute exact path='/robodance' component={RoboDanceList}/>
                <AuthorizeRoute exact path='/robodance/create' component={RoboDanceCreate}/>
                <AuthorizeRoute exact path='/robodance/edit/:id' component={RoboDanceCreate}/>

                <AuthorizeRoute exact path='/fishtank' component={FishTankList}/>
                <AuthorizeRoute exact path='/fishtank/create' component={FishTankCreate}/>
                <AuthorizeRoute exact path='/fishtank/edit/:id' component={FishTankCreate}/>

                <AuthorizeRoute exact path='/gameflow' component={GameFlowIndex} />
                <AuthorizeRoute exact path='/gameflow/create' component={GameFlowCreate} />
                <AuthorizeRoute exact path='/gameflow/edit/:id' component={GameFlowCreate} />

                <Route exact path="/login" component={Login} />
                <Route exact path="/logout" component={Logout} />
            </Layout>
        );
    }
}
