import React, {Component} from 'react'
import {GameTSType} from "../../domain/GameType";
import {GameFlowItem} from "../../domain/GameFlowItem";
import {Card, CardBody, CardFooter, CardHeader, CardImg, CardSubtitle, CardTitle} from "reactstrap";
import {toDoubleDigit} from "../../helper/StringFormat";
import {isHearts} from "../../domain/Hearts";
import {ImageWithTextPreview} from "./ImageWithTextPreview";
import {isSakura} from "../../domain/Sakura";
import {isCollage} from "../../domain/Collage";
import {isScratch} from "../../domain/Scratch";
import {isRoboDance} from "../../domain/RoboDance";
import RoboDanceBackground from "../../images/robodance_background.png";
import {isFishTank} from "../../domain/FishTank";
import FishTankBackground from "../../images/fishtank_background.png";
import {Link} from "react-router-dom";

interface Props{
    game: GameTSType | null;
    flow?: GameFlowItem | null;
    headline?: string;
    showEdit?: boolean;
    className?: string;
    onClick?: (game: GameTSType) => void;
}


export default  class GameCard extends Component<Props,{}>{

    render(){
        const game = this.props.game;
        const flow = this.props.flow;
        const headline = this.props.headline;

        if(!game) return false;

        return(
            <Card className={`game-card ${this.props.className ? this.props.className : ''}`} onClick={() => this.props.onClick && this.props.onClick(game)}>
                {(headline || flow) &&
                    <CardHeader>
                        {headline && <h2>{headline}</h2>}
                        {flow && <h6 className='text-muted'>{`${toDoubleDigit(flow.hours)}:${toDoubleDigit(flow.minutes)}`}</h6> }
                    </CardHeader>
                }
                { isHearts(game) &&
                <CardImg tag={ImageWithTextPreview} background={game.background} uiText={game.uiText} />
                }
                { isSakura(game) &&
                <CardImg tag={ImageWithTextPreview} background={game.background} uiText={game.uiText} />
                }
                { isCollage(game) &&
                <CardImg tag={ImageWithTextPreview} background={game.background} uiText={game.uiText} />
                }
                { isScratch(game) &&
                <div style={{position: "relative", width: "100%", paddingBottom: "62.5%"}}>
                    <CardImg
                        tag={ImageWithTextPreview}
                        background={game.background}
                        uiText={!game.textOnForeground ? game.uiText : null}
                        style={{position: "absolute", width: "80%", bottom: "0", right: "0"}}
                    />
                    <CardImg
                        tag={ImageWithTextPreview}
                        background={game.foreground}
                        uiText={game.textOnForeground ? game.uiText : null}
                        style={{position: "absolute", width: "80%", top: "0", left: "0"}}
                    />
                </div>

                }
                { isRoboDance(game) &&
                <CardImg tag={ImageWithTextPreview} uiText={game.uiText} background={{imagePath: RoboDanceBackground, id:"fake", thumbPath: RoboDanceBackground}} />
                }
                { isFishTank(game) &&
                <CardImg tag={ImageWithTextPreview} uiText={game.uiText} background={{imagePath: FishTankBackground, id:"fake", thumbPath: FishTankBackground}} />
                }
                <CardBody>
                    <CardTitle className='h3'>{game.name}</CardTitle>
                    <CardSubtitle className='text-muted' >{game.gameType}</CardSubtitle>
                </CardBody>
                {this.props.showEdit &&
                    <CardFooter>
                        <Link to={`/${game.gameType}/edit/${game.id}`}>
                            Edit game
                        </Link>
                    </CardFooter>
                }
            </Card>
        );
    }

};