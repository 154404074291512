import {DbImage} from "./DbImage";
import {BaseGame} from "./BaseGame";
import {UiText} from "./UiText";

export interface Hearts extends BaseGame{
    object: DbImage;
    background: DbImage;
    uiText: UiText | null;
}

export function isHearts(possible: any): possible is Hearts{
    const game = possible as Hearts;
    if(game.gameType === "Hearts")
    {
        return true;
    }
    return false;
}