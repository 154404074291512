import React, {Component, FormEvent, ReactNode} from 'react';
import authService from "../../api-authorization/AuthorizeService";
import {FormGroup, Jumbotron} from "reactstrap";
import {Redirect, RouteComponentProps} from "react-router";
import {TextFormGroup} from "../../shared/TextFormGroup";
import {RoboDance} from "../../../domain/RoboDance";
import {CreateFishTankRequest} from "../../../domain/CreateFishTankRequest";
import FishTankBackground from "../../../images/fishtank_background.png";
import {handleFetchError} from "../../shared/FormErrors";
import SubmitButton from "../../shared/SubmitButton";

interface MatchParams{
    id: string;
}
interface Props extends RouteComponentProps<MatchParams>{
}
interface State{
    form: CreateFishTankRequest;
    gameId: string | null;
    redirectToIndex: boolean;
    loading: boolean;
    error?: ReactNode;
}

export class FishTankCreate extends Component<Props, State>{

    constructor(props: Props){
        super(props);
        const id = this.props.match.params.id;
        this.state = {
            form: {
                name: '',
                text: null,
                textPositionX: 0,
                textPositionY: 0
            },
            gameId: id || null,
            redirectToIndex: false,
            loading: false
        }
    }

    componentDidMount(): void {
        if(this.state.gameId){
            this.getGame(this.state.gameId).then();
        }
    }

    async putGame(){
        this.setState({loading: true});
        const body: CreateFishTankRequest = this.state.form;
        const token = await authService.getAccessToken();
        const response = await fetch(`api/fishtank/${this.state.gameId}`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        });
        this.handleGameResponse(response, true);
    }

    async postGame(){
        this.setState({loading: true});
        const body: CreateFishTankRequest = this.state.form;
        const token = await authService.getAccessToken();
        const response = await fetch('api/fishtank', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        });
        this.handleGameResponse(response, true);
    }

    async getGame(id: string){
        this.setState({loading: true});
        const token = await authService.getAccessToken();
        const response = await fetch(`api/fishtank/${id}`, {
            headers: { 'Authorization': `Bearer ${token}` }
        });
        this.handleGameResponse(response, false);
    }

    async handleGameResponse(response: Response, redirectOnSuccess: boolean){
        try{
            if(response.status === 200){
                const data: RoboDance = await response.json();
                this.setState({
                    loading: false,
                    gameId: data.id,
                    form:{
                        ...this.state.form,
                        name: data.name,
                        text: data.uiText && data.uiText.text,
                        textPositionX: data.uiText && data.uiText.position ? data.uiText.position.x : 0,
                        textPositionY: data.uiText && data.uiText.position ? data.uiText.position.y : 0
                    },
                    redirectToIndex: redirectOnSuccess,
                });
            }
            else{
                this.setState({loading: false, error: await handleFetchError(response)});
            }
        }
        catch (e) {
            this.setState({loading: false, error: `Unknown error`});
        }
    }

    render(){

        if(this.state.redirectToIndex){
            return <Redirect to={'/fishtank'} />;
        }

        const onSubmit = (event: FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            if(this.state.gameId){
                this.putGame().then();
            }
            else{
                this.postGame().then();
            }
        };

        return (
            <div>
                <Jumbotron>
                    <h1>FishTank</h1>
                    <p>
                        In fishtank you can look at pretty fish, <br/>
                        You can make the fish swim towards you by raising your hand.<br/>
                        You can also insert a text as a overlay somewhere on the screen.<br/>
                    </p>
                </Jumbotron>

                <section>
                    <h2>FishTank form</h2>
                    <form onSubmit={onSubmit}>
                        {this.state.error}
                        <FormGroup>
                            <label htmlFor='name'>Name</label>
                            <input
                                className='form-control'
                                type='text'
                                id='name'
                                name='name'
                                value={this.state.form.name}
                                onChange={(e) =>
                                    this.setState({
                                        form: {
                                            ...this.state.form,
                                            name: e.target.value
                                        }
                                    })
                                }
                            />
                        </FormGroup>

                        <TextFormGroup
                            text={this.state.form.text}
                            onTextChange={(t) => this.setState({form: {...this.state.form, text: t}})}
                            onPosChange={(p) => this.setState({form: {...this.state.form, textPositionX: p.x, textPositionY: p.y}})}
                            textPos={{x:this.state.form.textPositionX, y: this.state.form.textPositionY}}
                            background={{imagePath: FishTankBackground, id:"fake", thumbPath: FishTankBackground}}
                        />

                        {this.state.error}
                        <SubmitButton disabled={this.state.loading} isCreate={!!this.state.gameId}  />
                    </form>
                </section>
            </div>
        )
    }
}