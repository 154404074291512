import * as React from 'react';
import {Component} from 'react';
import {Button, Card, CardHeader, Col, ListGroup, ListGroupItem, Row} from "reactstrap";
import ImageForm from "./ImageForm";
import {DbImage} from "../../domain/DbImage";
import authService from "../api-authorization/AuthorizeService";
import {ImageUploadType} from "../../domain/ImageUploadType";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";

interface Props{
    title: string;
    subtitle?: string;
    endpoint: string;
    onImageClick: (img: DbImage) => void;
    setImageClass: (imgId: string) => string;
    aspectRatio: number;
    fixedAspect: boolean;
}

interface State{
    images: Array<DbImage>;
    showUpload: boolean;
    getLoading: boolean;
    postLoading: boolean;
    errorUpload: false|string;
    deleteSure: false|string;
}

export default class ImageBank extends Component<Props, State>{

    constructor(props:Props){
        super(props);
        this.state = {
            images: [],
            showUpload: false,
            getLoading: false,
            postLoading: false,
            errorUpload: false,
            deleteSure: false
        }
    }

    componentDidMount(): void {
        const getImages = async () =>  {
            this.setState({ getLoading: true });
            const token = await authService.getAccessToken();
            const response = await fetch(this.props.endpoint, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });
            const data = await response.json();
            this.setState({ images: data, getLoading: false });
        };

        getImages().then();
    }

    render(){
        const postImage = async (body: ImageUploadType) => {
            this.setState({postLoading: true, errorUpload: false});
            try{
                const token = await authService.getAccessToken();
                const response = await fetch(this.props.endpoint, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(body)
                });
                if(response.status === 200){
                    const data = await response.json();
                    this.setState({ images: [...this.state.images, data], postLoading: false });
                }
                else{
                    this.setState({postLoading: false, errorUpload: `${response.status} ${response.statusText}`});
                }
            }
            catch (e) {
                this.setState({postLoading: false, errorUpload: e})
            }
        };

        const deleteImage = async (imgId: string) => {
            if(this.state.deleteSure !== imgId){
                this.setState({deleteSure: imgId});
                return;
            }
            try{
                const token = await authService.getAccessToken();
                const response = await fetch(`${this.props.endpoint}/${imgId}`, {
                    method: 'DELETE',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                });
                if(response.status === 200){
                    this.setState({ images: this.state.images.filter(x => x.id !== imgId), postLoading: false });
                }
            }
            catch (e) {
                //ignore
            }
        };


        return(
            <Card>
                <CardHeader>
                    <h2>{this.props.title}</h2>
                    {this.props.subtitle && <p>{this.props.subtitle}</p>}
                </CardHeader>
                <CardHeader>
                    {!this.state.showUpload ? <Button color='info' onClick={() => this.setState({showUpload: true})}>Upload new image</Button> :
                        <section className='image-upload-section'>
                            <h2>New Image</h2>
                            {this.state.errorUpload && <div>{this.state.errorUpload}</div>}
                            {this.state.postLoading ? <div>Uploading...</div> :
                                <ImageForm
                                    aspectRatio={this.props.aspectRatio}
                                    fixedAspect={this.props.fixedAspect}
                                    onSubmit={(img) => {postImage(img).then()}}
                                    onCancel={() => this.setState({showUpload: false})}
                                    previewWidth={160}
                                    previewHeight={100}
                                />
                            }
                        </section>
                    }
                </CardHeader>
                <ListGroup>
                    <ListGroupItem>
                        <Row className='image-pool'>
                            {this.state.getLoading && <div>Loading images...</div>}
                            {this.state.images.filter(x => this.props.setImageClass(x.id)).map(img =>
                                <Col key={img.id}>
                                    <div>
                                        <img
                                            className={this.props.setImageClass(img.id)}
                                            src={img.thumbPath}
                                            alt=''
                                            onClick={() => this.props.onImageClick(img)}
                                        />
                                        <div
                                            className='delete'
                                            onClick={() => deleteImage(img.id)}
                                        >
                                            <FontAwesomeIcon icon={faTrash} />
                                            {this.state.deleteSure === img.id ? 'Are you sure?' : 'Delete'}
                                        </div>
                                    </div>

                                </Col>
                            )}
                        </Row>
                    </ListGroupItem>
                    <ListGroupItem>
                        <Row className='image-pool'>
                            {this.state.getLoading && <div>Loading images...</div>}
                            {this.state.images.map(img =>
                                <Col key={img.id}>
                                    <div>
                                        <img
                                            className={this.props.setImageClass(img.id)}
                                            src={img.thumbPath}
                                            alt=''
                                            onClick={() => this.props.onImageClick(img)}
                                        />
                                        <div
                                            className='delete'
                                            onClick={() => deleteImage(img.id)}
                                        >
                                            <FontAwesomeIcon icon={faTrash} />
                                            {this.state.deleteSure === img.id ? 'Are you sure?' : 'Delete'}
                                        </div>
                                    </div>

                                </Col>
                            )}
                        </Row>
                    </ListGroupItem>
                </ListGroup>
            </Card>
        )
    }
}