import React from 'react'
import { Component } from 'react'
import {Route, Redirect, RouteProps} from 'react-router-dom'
import authService from './AuthorizeService'

interface Props extends RouteProps{}
interface State{
    ready: boolean;
    authenticated: boolean;
}

export default class AuthorizeRoute extends Component<Props, State> {
    private _subscription!: number;

    constructor(props: Props) {
        super(props);

        this.state = {
            ready: false,
            authenticated: false
        };
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.authenticationChanged());
        this.populateAuthenticationState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        authService.refreshTokenIfCloseToExpire().then();
    }

    render() {
        const { ready, authenticated } = this.state;

        if (!ready) {
            return <div />;
        } else {
            const { component: Component, ...rest } = this.props;
            return <Route {...rest}
                render={(props) => {
                    if (authenticated) {
                        // @ts-ignore
                        return <Component {...props} />
                    } else {
                        const redirectUrl = `/login?returnUrl=${encodeURI(window.location.pathname)}`;
                        return <Redirect to={redirectUrl} />
                    }
                }} />
        }
    }

    async populateAuthenticationState() {
        const authenticated = await authService.isAuthenticated();
        this.setState({ ready: true, authenticated });
    }

    async authenticationChanged() {
        this.setState({ ready: false, authenticated: false });
        await this.populateAuthenticationState();
    }
}
