import React, {Component} from 'react';
import {GameTSType} from "../domain/GameType";
import {GameData} from "../data/GameData";
import {GameFlowData} from "../data/GameFlowData";
import {isResponseError} from "../domain/ResponseError";
import {SortByTime} from "../helper/SortFunctions";
import moment from "moment";
import {GameFlowItem} from "../domain/GameFlowItem";
import {Col, Row} from "reactstrap";
import GameCard from "./shared/GameCard";

interface State{
    activeGame: GameTSType | null;
    activeFlowItem: GameFlowItem | null;

    nextGame: GameTSType | null;
    nextFlowItem: GameFlowItem | null;

    loading: boolean;
}
interface Props{

}

export default class Home extends Component<Props,State>{

    _gameData = new GameData();
    _gameFlowData = new GameFlowData();

    constructor(props: Props){
        super(props);

        this.state = {
            activeGame: null,
            activeFlowItem: null,

            nextGame: null,
            nextFlowItem: null,

            loading: true
        };
    }

    componentDidMount(): void {
        this._gameFlowData.getActiveFlow().then(x => {
            if(!isResponseError(x)){
                //if there is no flow in the active flow just return
                if(!x.gameFlow || !x.gameFlow.flow || x.gameFlow.flow.length === 0){
                    return;
                }

                //find the first game that starts later that now, the game before this must be the current game.
                //if no game is found that starts later, the last game is the list must be the active one.
                const now = moment();
                const nowFromMidnight = now.hours() * 60 + now.minutes();
                const sortedFlows = x.gameFlow.flow.sort(SortByTime);
                const index = sortedFlows.findIndex(x => x.hours * 60 + x.minutes > nowFromMidnight);

                let activeGameFlowItem: GameFlowItem|null = null;
                let nextGameFlowItem: GameFlowItem|null = null;
                if(index < 1){
                    //last game must be the active one
                    activeGameFlowItem = sortedFlows[sortedFlows.length -1];
                    if(sortedFlows.length > 1){
                        nextGameFlowItem = sortedFlows[0];
                    }
                }
                else{
                    //the game before the next game must be the active one
                    activeGameFlowItem = sortedFlows[index-1];
                    nextGameFlowItem = sortedFlows[index];
                }
                if(activeGameFlowItem){
                    this._gameData.getGame<GameTSType>(activeGameFlowItem.gameType, activeGameFlowItem.gameId).then(x => {
                        if(!isResponseError(x)){
                            this.setState({
                                activeGame: x,
                                loading: false,
                                activeFlowItem: activeGameFlowItem
                            })
                        }
                    })
                }
                if(nextGameFlowItem){
                    this._gameData.getGame<GameTSType>(nextGameFlowItem.gameType, nextGameFlowItem.gameId).then(x => {
                        if(!isResponseError(x)){
                            this.setState({
                                nextGame: x,
                                loading: false,
                                nextFlowItem: nextGameFlowItem
                            })
                        }
                    })
                }
            }
        })
    }

    render(){

        return (
            <div>
                {this.state.loading && <div>Finding the active game...</div>}
                <Row>
                    <Col sm={6}>
                        <GameCard game={this.state.activeGame} flow={this.state.activeFlowItem} headline={"Current game"} showEdit />
                    </Col>
                    <Col sm={6}>
                        <GameCard game={this.state.nextGame} flow={this.state.nextFlowItem} headline={"Up next"} showEdit />
                    </Col>

                </Row>
            </div>
        )
    }
}