import {ResponseError} from "../domain/ResponseError";

export async function handleResponse<T>(response: Response, endpoint: string): Promise<T|ResponseError>{
    try{
        if(response.status >= 200 && response.status < 300){
            const text = await response.text();
            return text ? JSON.parse(text) : {};
        }
        else{
            return {
                status: response.status,
                endpoint: endpoint,
                errorMsg: `${response.status} ${response.statusText}`
            };
        }
    }
    catch (e) {
        return {
            status: 0,
            endpoint: endpoint,
            errorMsg: e.message
        }
    }
}