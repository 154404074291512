export interface ResponseError{
    status: number;
    errorMsg: string;
    endpoint: string;
}

export function isResponseError(possibleError: any): possibleError is ResponseError{
    const error = possibleError as ResponseError;
    if(
        error.status !== undefined &&
        error.errorMsg !== undefined &&
        error.endpoint !== undefined
    )
    {
        return true;
    }

    return false;
}