import React, {Component, FormEvent} from 'react';
import {Alert, Button, Col, FormGroup} from "reactstrap";
import {isResponseError, ResponseError} from "../../domain/ResponseError";
import {AccountData} from "../../data/AccountData";
import {Redirect, RouteComponentProps} from "react-router";
import {AccountUpdateModel} from "../../domain/AccountModel";

interface MatchParams{
    id: string;
}
interface Props extends RouteComponentProps<MatchParams>{
}

interface State{
    id: string;
    model: AccountUpdateModel;
    loading: boolean;
    error: ResponseError | false;
    shouldRedirect: boolean;
}

export default class AccountEdit extends Component<Props, State>{

    _accountData = new AccountData();

    constructor(props: Props){
        super(props);
        const id = this.props.match.params.id;

        this.state = {
            id: id,
            model: {email: ""},
            loading: false,
            error: false,
            shouldRedirect: false
        }
    }

    componentDidMount(): void {
        this.setState({loading: true});

        this._accountData.get(this.state.id).then(x => {
            if(!isResponseError(x)){
                this.setState({model: {email: x.email}, loading: false});
            }
            else{
                this.setState({error: x, loading: false})
            }

        })
    }

    async onSubmit(e: FormEvent){
        e.preventDefault();

        this.setState({loading: true});

        this._accountData.put(this.state.id, this.state.model).then(x => {
            if(!isResponseError(x)){
                this.setState({shouldRedirect: true, loading: false});
            }
            else{
                this.setState({error: x, loading: false});
            }
        })
    }

    render(){
        if(this.state.shouldRedirect) return <Redirect to='/account' />;

        return(
            <Col md={6} lg={4}>
                <h1>Create Account</h1>
                {this.state.error && <Alert color='danger'>{this.state.error.errorMsg}</Alert>}
                {this.state.loading ? "Loading..." :
                    <form onSubmit={(e) => this.onSubmit(e)}>
                        <FormGroup>
                            <label>Email</label>
                            <input
                                className='form-control'
                                type='email'
                                value={this.state.model.email}
                                onChange={e => this.setState({
                                    model: {
                                        ...this.state.model,
                                        email: e.target.value
                                    }})
                                }
                            />
                        </FormGroup>
                        <FormGroup>
                            <Button type='submit' color='success'>Update</Button>
                        </FormGroup>
                    </form>
                }
            </Col>
        )
    }
}