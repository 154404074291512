import {BaseGame} from "./BaseGame";
import {UiText} from "./UiText";

export interface FishTank extends BaseGame{
    uiText: UiText;
}

export function isFishTank(possible: any): possible is FishTank{
    const game = possible as FishTank;
    if(game.gameType === "FishTank")
    {
        return true;
    }
    return false;
}