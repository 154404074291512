import React, {Component} from 'react';
import authService from "../../api-authorization/AuthorizeService";
import {Link} from "react-router-dom";
import {Sakura} from "../../../domain/Sakura";
import {Button, Table} from "reactstrap";
import {DbOrUploadImage} from "../../../helper/ImageHelper";

interface Props{

}
interface State{
    games: Sakura[];
    loading: boolean;
    deleteSure: false|string;
}

export class SakuraList extends Component<Props, State>{
    constructor(props: Props){
        super(props);
        this.state = {games: [], loading: false, deleteSure: false}
    }

    componentDidMount(): void {
        this.getGames().then();
    }

    async getGames(){
        const token = await authService.getAccessToken();
        const response = await fetch('api/sakura', {
            headers: { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        this.setState({ games: data, loading: false });
    }

    async deleteGames(gameId: string){
        if(this.state.deleteSure !== gameId){
            this.setState({deleteSure: gameId});
            return;
        }
        const token = await authService.getAccessToken();
        const response = await fetch(`api/sakura/${gameId}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        });
        if(response.status === 200){
            this.setState({ games: this.state.games.filter(x => x.id !== gameId)});
        }
    }

    render(){
        return(
            <div>
                <h1>Sakura Games</h1>
                <div>
                    <Link to="/sakura/create">
                        <Button className={'mb-2'} color='primary'>
                            Create new
                        </Button>
                    </Link>
                </div>
                { this.state.loading ? <p>Loading...</p> :
                    <section>
                        <Table>
                            <thead>
                            <tr>
                                <th>Name</th>
                                <th>Background</th>
                                <th>Objects</th>
                                <th>Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.games.map(x => {
                                return(
                                    <tr key={x.id}>
                                        <td>{x.name}</td>
                                        <td><DbOrUploadImage image={x.background} /></td>
                                        <td>{x.objects.map(y => <DbOrUploadImage className='small' key={y.id} image={y} />)}</td>
                                        <td>
                                            <div className='btn-group-custom'>
                                                <Link to={`/sakura/edit/${x.id}`}> <Button color='info' size='sm'>edit</Button></Link>
                                                <Button
                                                    color='danger'
                                                    size='sm'
                                                    onClick={() => {this.deleteGames(x.id).then()}}
                                                >
                                                    {this.state.deleteSure === x.id ? 'are you sure?' : 'delete' }
                                                </Button>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}

                            </tbody>
                        </Table>
                    </section>
                }
            </div>
        )
    }
}